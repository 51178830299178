import Axios from 'axios';
import { createStore, applyMiddleware } from 'redux';
import { createLogicMiddleware } from 'redux-logic';
import { persistStore } from 'redux-persist';

// import logger from 'redux-logger';

import rootReducer from './root-reducer';
import { updateUserProfileLogic } from './user/user.logic';

const deps = {
    httpClient: Axios
};

const arrLogic = [updateUserProfileLogic];

const logicMiddleware = createLogicMiddleware(arrLogic, deps);

const middlewares = [logicMiddleware];

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);

export default {store, persistor}; 
import React from 'react';

const Dashboard = () => (
        <div className="row">
            <div className="col-md-12">
                <div className="card m-b-30">
                    <div className="card-body text-center">
                        <img alt="connected" src="/assets/images/check-ok.gif" width="180px" />
                        <h2>Connected</h2>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-body text-center brand-icon">
                        <img alt="connected" src="/assets/images/zoomus-icon.svg" width="50px" />
                        <h3><span className="material-icons">done</span> Zoom</h3>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-body text-center brand-icon">
                        <img alt="connected" src="/assets/images/gdrive.svg" width="50px" />
                        <h3>
                        <span className="material-icons"> done </span> Google Drive</h3>
                    </div>
                </div>
            </div>
        </div>
)

export default Dashboard;

import React from 'react';
import { Link } from 'react-router-dom';

const PageTitle = (props) => (
    <>
        <section className={props.singlePage ? 'bg-half bg-light d-table w-100' : 'bg-half-170 bg-light d-table w-100'}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 text-center">
                        <div className="page-next-level">
                            <h4 className="title">{props.title} </h4>
                            {
                                props.cat ? (
                                    <div className="page-next">
                                        <nav aria-label="breadcrumb" className="d-inline-block">
                                            <ul className="breadcrumb bg-white rounded shadow mb-0 bc-sm">
                                                <li className="breadcrumb-item"><a href="/offers">المتجر</a></li>
                                                <li className="breadcrumb-item"><Link to={`/offers/${props.cat.slug}/all`} >{props.cat.name}</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">{props.name}</li>
                                            </ul>
                                        </nav>
                                    </div>
                                ): null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
    </>
);

export default PageTitle;
import React from 'react';
import { Redirect } from 'react-router-dom';
import { API } from '../../../config/api';
import NotificationSystem from 'react-notification-system';
import axios from 'axios';
import { setCurrentUserToken, updateUserProfile } from '../../../redux/user/user.actions';
import { connect } from 'react-redux';


class EmailConfirmation extends React.Component {
    notificationSystem = React.createRef();
    
    state = {
        redirect: false,
    }

    async componentDidMount() {

        const token = this.props.match.params.token;
        const notification = this.notificationSystem.current;
        const { setCurrentUserToken, updateUserProfile } = this.props;
        
        let url = `${API.CMS}/auth/email-confirmation?confirmation=${token}`;
        
        if(token.length < 150) {
            this.setState({ redirect: true });
            return;
        }

        await axios.get(url);

        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        await updateUserProfile();
        
        notification.addNotification({
            message: 'لقد تم تفعيل الحساب بنجاح',
            position: 'tr',
            dismissible: 'click',
            level: 'success'
        });
        setTimeout(() => { 
            setCurrentUserToken(token);
        }, 1000);

    }
    render() {
        return (
            <div style={{height: '600px'}}> 
                <NotificationSystem ref={this.notificationSystem} />
                { this.state.redirect ? (<Redirect push to="/"/>) : null }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    lang: state.lang.languages,
});
const mapDispatchToProps = (dispatch) => ({
    setCurrentUserToken: (token) => dispatch(setCurrentUserToken(token)),
    updateUserProfile: () => dispatch(updateUserProfile())
});
export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmation);
import React from 'react';
import { connect } from "react-redux";
import axios from 'axios';

import { API } from '../../../config/api'; 
import { setCurrentUser, setCurrentUserToken } from '../../../redux/user/user.actions';
import { Link } from 'react-router-dom';
import  errorResponseHandler  from '../../../errorHandler';

class Signin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            signinForm: true,
            email: '',
            password: '',
            token: '',
            message: undefined,
            onSubmit: false
        }
    }
    componentDidMount() {
        errorResponseHandler();

    }
    renderRedirect() {
        // return <Redirect to='/app/dashboard' />
    }

    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({ [name]: value });
    }

    handleForgotPassword = () => {
        this.clearMessage();
        const { lang } = this.props;
        let _this = this;
        let url = `${API.CMS}/auth/forgot-password`;
        axios.post(url, {
            email: this.state.email
        })
        .then(() => {
            _this.setState({
                message: {
                    text: lang.message_forgot_password_sent,
                    type: 'success'
                }
            })
        })
        .catch((error) => {
            console.log(error);
        })
    }
    clearMessage = () => {
        this.setState({
            message: undefined
        });
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            onSubmit: true
        });
        let _this = this;
        const { setCurrentUser, setCurrentUserToken, lang} = this.props;
        const { signinForm } = this.state;
        if(!signinForm) {
            this.handleForgotPassword();
            return;
        }
        this.clearMessage();
        axios.post(`${API.CMS}/auth/local`, {
            identifier: this.state.email,
            password: this.state.password
          })
          .then(function (response) {
            setCurrentUser(response.data.user);
            setCurrentUserToken(response.data.jwt);
            axios.interceptors.request.use(
                function (config) {
                    config.headers.Authorization = 'Bearer ' + response.data.jwt;
                    return config;
                },
                error => {
                    Promise.reject(error);
                    return;
                }
            );
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.jwt;
        })
        .catch(function (error) {
            _this.setState({
                message: {
                    text: lang.message_wrong_user_or_pass,
                    type: 'danger'
                },
                onSubmit: false
            })
        });
    }

    changeForm = () => {
        const { signinForm } = this.state;
        this.setState({
            signinForm: !signinForm,
            onSubmit: false,
            message: undefined
        })
    }

    render() {
        const { lang } = this.props;
        const {signinForm, message, onSubmit} = this.state;
        return (
            <section className="bg-home bg-circle-gradiant d-flex align-items-center">
                <div className="bg-overlay bg-overlay-white"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8">
                            <div className="card login-page bg-white shadow rounded border-0">
                                <div className="card-body">
                                    <h4 className="card-title text-center">{signinForm ? lang.signin : lang.forgot_password}</h4>
                                    {
                                        message &&  
                                        <div className={`alert alert-${message.type} alert-dismissible fade show mb-0`}>
                                            {message.text}
                                        </div>
                                    }
                                        <form className="login-form mt-4" onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group position-relative">
                                                        <label>{lang.email} <span className="text-danger">*</span></label>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                        <input
                                                            type="text"
                                                            className="form-control pl-5"
                                                            placeholder={lang.email}
                                                            name="email"
                                                            value={this.state.email}
                                                            onChange={this.handleInputChange}
                                                            required 
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    signinForm &&
                                                    <>
                                                        <div className="col-lg-12">
                                                            <div className="form-group position-relative">
                                                                <label>{lang.password} <span className="text-danger">*</span></label>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                                                <input
                                                                    type="password"
                                                                    className="form-control pl-5"
                                                                    placeholder={lang.password}
                                                                    name="password"
                                                                    value={this.state.password}
                                                                    onChange={this.handleInputChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                                        <label className="custom-control-label" htmlFor="customCheck1">{lang.remember_me}</label>
                                                                    </div>
                                                                </div>
                                                                <p className="forgot-pass mb-0"><span className="text-dark btn btn-link p-0" style={{fontSize: '13px'}} onClick={this.changeForm}>{lang.forgot_password}</span></p>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className="col-lg-12 mb-0">
                                                    <button className="btn btn-primary btn-block" disabled={onSubmit} type="submit">{signinForm ? lang.signin : lang.submit}</button>
                                                </div>
                                                <div className="col-12 text-center">
                                                        {
                                                            signinForm ? (
                                                                <p className="mb-0 mt-3"><small className="text-dark mr-2">{lang.dont_have_account}</small>
                                                                    <Link to="/registration" className="text-dark font-weight-bold" >{lang.register_now}</Link>
                                                                </p>
                                                            ): (
                                                                <button onClick={this.changeForm} className="btn btn-link mt-4 p-0 text-dark font-weight-bold">{lang.signin}</button>
                                                            )
                                                        }
                                                </div>
                                            </div>
                                        </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    lang: state.lang.languages
});

const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user)),
    setCurrentUserToken: token => dispatch(setCurrentUserToken(token))
});
 
export default connect(mapStateToProps, mapDispatchToProps)(Signin);

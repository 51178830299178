import React from "react";
import NotificationSystem from 'react-notification-system';
import { setCurrentUser, updateUserProfile } from "../../../redux/user/user.actions";
import { connect } from "react-redux";
import { API } from "../../../config/api";
import axios, { post } from "axios";
import Account from "../../../components/account/Account.component";

class MyAccount extends React.Component {
  notificationSystem = React.createRef();


  constructor(props) {
    super(props);
    this.state = {
      companyLogo: null,
      imagePreviewUrl: null,
      currentUser: this.props.currentUser,
      cities: []
    };
  }
  fileUpload = (file) => {
    const url = API.CMS + "/upload";
    const formData = new FormData();
    formData.append("files", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return post(url, formData, config);
  };

    handleInputChange = (event) => {
        const { value, name } = event.target;
        let newArray = this.state.currentUser;
        newArray = { ...newArray, [name]: value }
        this.setState({
            currentUser: newArray
        })
    };

  onChangeFile = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) return [];
    reader.onloadend = () => {
      this.setState(
        {
          companyLogo: file,
          imagePreviewUrl: reader.result,
        });
    };

    reader.readAsDataURL(file);
    this.setState({ companyLogo: e.target.files[0] }, () => {
      this.fileUpload(this.state.companyLogo).then((response) => {
        if(response.data && response.data[0]) {
            let newArray = this.state.currentUser;
            let company_logo = response.data;
            
            newArray = { ...newArray,  company_logo }
            this.setState({
                currentUser: newArray
            })
        }
      });
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const notification = this.notificationSystem.current;
    const { currentUser } = this.state;
    const { updateUserProfile } = this.props;
    let city = currentUser.city;
    let company_logo = currentUser.company_logo;
    if(currentUser.city.id) {
        city = currentUser.city.id;
    }
    if(currentUser.company_logo && currentUser.company_logo.length) {
      company_logo = currentUser.company_logo[0].id;
    }
    await axios.put(API.CMS + `/users/${currentUser.id}`, {
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        phone: currentUser.phone,
        company_name: currentUser.company_name,
        address: currentUser.address,
        city: city,
        company_about: currentUser.company_about,
        company_logo: company_logo
    });

    await updateUserProfile();

    notification.addNotification({
      message: 'تم التحديث بنجاح',
      position: 'tr',
      dismissible: 'click',
      level: 'success'
    });

  }

  async componentDidMount() {  
    let city = await axios.get(API.CMS + `/cities`); 
      this.setState({
          cities: city.data
      })
  }

  render() {
  
    const { imagePreviewUrl, currentUser, cities } = this.state;
    const { lang } = this.props;
    return (
      <div> 
      <NotificationSystem ref={this.notificationSystem} />
        <Account 
          currentUser={currentUser}
          lang={lang}
          imagePreviewUrl={imagePreviewUrl}
          onChangeFile={this.onChangeFile}
          handleInputChange={this.handleInputChange}
          cities={cities}
          handleSubmit={this.handleSubmit}
          button={lang.save}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  lang: state.lang.languages
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  updateUserProfile: () => dispatch(updateUserProfile())
});
export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
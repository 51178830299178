import React from 'react';
import { Link } from 'react-router-dom';
import { API } from '../../config/api';
import { connect } from 'react-redux';
function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }
const Offer = prop => (
    <div className={prop.styles ? `${prop.styles} mt-4 pt-2 offer` : `col-lg-4 col-md-6 col-12 mt-4 pt-2 offer`}>
        <div className="card courses-desc overflow-hidden rounded shadow border-0">
            <div className="position-relative d-block overflow-hidden">
                {Object.keys(prop.offer.photo).length > 0 ? (
                    <img src={`${API.CMS}${prop.offer.photo && prop.offer.photo[0] && prop.offer.photo[0].formats ? prop.offer.photo[0].formats.small.url : prop.offer.photo[0].url}`} className="img-fluid rounded-top mx-auto" alt="" />
                ) : (
                        <img src="https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg" alt="" />
                    )}
                <div className="overlay-work"></div>
                <Link to={`/offer/${prop.offer.id}/${prop.offer.slug}`} className="text-white h6 preview"><i className="mdi mdi-chevron-right"></i> {prop.lang.show_offer} </Link>
            </div>
            <div className="card-body">
                <h5>
                    <Link to={`/offer/${prop.offer.id}/${prop.offer.slug}`} className="title text-dark">{prop.offer.name}</Link>
                </h5>
                <p className="text-muted">{prop.offer.description ? truncate(prop.offer.description, 90) : ''}</p>
                <div className="fees d-flex justify-content-between">
                    <ul className="list-unstyled mb-0 numbers">
                        <li><i className="mdi mdi-basket text-muted"></i> {prop.lang.quantity}: {prop.offer.amount}  <small>{prop.offer.unit.name}</small></li>
                        {prop.offer.transport ? (<li><i className="mdi mdi-truck text-muted"></i> {prop.lang.deliver}</li>) : null}
                    </ul>
                    {prop.offer.priceByAgreement ? (
                        <h4><span className="h6"></span>{prop.lang.by_agreement}</h4>
                    ) : (
                            <h4><span className="h6"></span>
                                {prop.offer.price}₪&nbsp;
                                <span className="badge badge-pill badge-outline-light">{prop.offer.pricePerUnit ? (<small>{prop.lang.per_unit}</small>) : (<small>{prop.lang.for_total}</small>)}</span>
                            </h4>
                        )}
                </div>
            </div>
        </div>
    </div>
);

const mapStateToProps = state => ({
    lang: state.lang.languages
});
  
export default connect(mapStateToProps)(Offer);
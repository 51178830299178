import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { API } from '../../config/api';
import parse from 'html-react-parser';


const Footer = (props) => (
  <>
  <div className="position-relative">
            <div className="shape overflow-hidden text-footer">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                    <a href="/" className="logo-footer">
                        <img src={`${API.CMS}${props.footer.logo.url}`} height="70" alt="" />
                    </a>
                    <p className="mt-4">
                        {parse(`${props.footer.about_us}`)}
                    </p>
                </div>
                
                <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h4 className="text-light footer-head">{props.footer.footer_menu.title}</h4>
                    <ul className="list-unstyled footer-list mt-4">
                        {
                            props.footer.footer_menu.links.map((link, idx) => (
                                <li key={idx}><Link to={link.url} className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> {link.title}</Link></li>
                            ))
                        }
                    </ul>
                </div>
                
                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h4 className="text-light footer-head">{props.footer.second_menu.title}</h4>
                    <ul className="list-unstyled footer-list mt-4">
                        {
                            props.footer.second_menu.links.map((link, idx) => (
                            <li key={idx}>
                                <a href={idx === 0 ? `mailto:${link.url}` : `tel:${link.url}`} className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> {link.title}
                                <br/> {link.url}</a></li>
                            ))
                        }
                    </ul>
                </div>

                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h4 className="text-light footer-head">{props.lang.partners}</h4>
                    {
                        props.footer.partners.map((partner, idx) => (
                            <div key={idx}>
                                <br/>
                                <p><a href={partner.website}><img src={`${API.CMS}${partner.logo.url}`} alt="" /></a></p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    </footer>
    </>
);


const mapStateToProps = state => ({
    lang: state.lang.languages
});
  
export default connect(mapStateToProps)(Footer);
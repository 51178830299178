import React from "react";
import { connect } from "react-redux"; 
import { setCurrentUser } from "../../../redux/user/user.actions";
import axios from "axios";
import { API } from "../../../config/api";

class Profile extends React.Component {
  async componentDidMount() {
    const { setCurrentUser, token } = this.props;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    let user = await axios.get(API.CMS + '/users/me');
    let city = await axios.get(API.CMS + `/cities?id=${user.data.city}`)
    if(city.data && city.data[0]) {
      user.data.city = city.data[0];
    }
    setCurrentUser(user.data);
  }
  render() {
    const { currentUser, lang } = this.props;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="media align-items-center mt-4">
            <i
              data-feather="mail"
              className="fea icon-ex-md text-muted mr-3"
            ></i>
            <div className="media-body">
              <h6 className="text-primary mb-0">{lang.about_company}</h6>
              <span className="text-muted">{currentUser.company_about}</span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="media align-items-center mt-4">
            <i
              data-feather="mail"
              className="fea icon-ex-md text-muted mr-3"
            ></i>
            <div className="media-body">
              <h6 className="text-primary mb-0">{lang.firstname}</h6>
              <span className="text-muted">
              {currentUser.firstname}
              </span>
            </div>
          </div>
          <div className="media align-items-center mt-4">
            <i
              data-feather="mail"
              className="fea icon-ex-md text-muted mr-3"
            ></i>
            <div className="media-body">
              <h6 className="text-primary mb-0">{lang.email}</h6>
              <span className="text-muted">
              {currentUser.email}
              </span>
            </div>
          </div>
          <div className="media align-items-center mt-4">
            <i
              data-feather="mail"
              className="fea icon-ex-md text-muted mr-3"
            ></i>
            <div className="media-body">
              <h6 className="text-primary mb-0">{lang.company_name}</h6>
              <span className="text-muted">
              {currentUser.company_name}
              </span>
            </div>
          </div>
          <div className="media align-items-center mt-4">
            <i
              data-feather="mail"
              className="fea icon-ex-md text-muted mr-3"
            ></i>
            <div className="media-body">
              <h6 className="text-primary mb-0">{lang.city}</h6>
              <span className="text-muted">
              {currentUser.city.name}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="media align-items-center mt-4">
            <i
              data-feather="mail"
              className="fea icon-ex-md text-muted mr-3"
            ></i>
            <div className="media-body">
              <h6 className="text-primary mb-0">{lang.lastname}</h6>
              <span className="text-muted">
              {currentUser.lastname}
              </span>
            </div>
          </div>
          <div className="media align-items-center mt-4">
            <i
              data-feather="mail"
              className="fea icon-ex-md text-muted mr-3"
            ></i>
            <div className="media-body">
              <h6 className="text-primary mb-0">{lang.phone}</h6>
              <span className="text-muted">
              <span dir="ltr">{currentUser.phone}</span>
              </span>
            </div>
          </div>
          <div className="media align-items-center mt-4">
            <i
              data-feather="mail"
              className="fea icon-ex-md text-muted mr-3"
            ></i>
            <div className="media-body">
              <h6 className="text-primary mb-0">{lang.address}</h6>
              <span className="text-muted">
              {currentUser.address}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="media align-items-center mt-4">
            <i
              data-feather="mail"
              className="fea icon-ex-md text-muted mr-3"
            ></i>
            <div className="media-body">
              <h6 className="text-primary mb-0">{lang.what_your_company_does}</h6>
              <span className="text-muted">
                {currentUser.company_about}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  token: state.user.token,
  lang: state.lang.languages
});

const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user))
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import MenuTypes from './menu.types';

const INITIAL_STATE = {
  showMenu: false,
};


const menuReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case MenuTypes.TOGGLE_MENU:
        if(action.payload === state.showMenu ) return state;
        return {
            ...state,
            showMenu: action.payload ? action.payload : !state.showMenu,
        }
        default: 
            return state;
    }
}

export default menuReducer;

import React from 'react';
import axios from 'axios';
import PageTitle from '../../../components/page-title/page-title.component';
import { API } from '../../../config/api';
import _ from "lodash";
import Filter from '../../../components/filter/filter.component';
import Offer from '../../../components/offer/offer.component';

class Offers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offers: [],
            total: [],
            categories: [],
            cities: [],
            pagination: 1,
            page: 1,
            limit: 12,
            category: null,
            city: null,
            q: null
        }
    }
    async componentDidMount() { 
        this.getOffers();
    }
    getOffers = async () => {
        let    
            category = this.props.match.params.category,
            city = this.props.match.params.city,
            start = 0,
            url = '&',
            search =this.props.location.search,
            params = new URLSearchParams(search),
            page = params.get('page'),
            q = params.get('q')
        ;
        if(category && category !== 'all') {
            if(url !== '&') url += '&';
            url += `category.slug=${category}`;
            this.setState({
                category: category
            });
        }
        if(city && city !== 'all') {
            if(url !== '&') url += '&';
            url += `city.slug=${city}`;
            this.setState({
                city: city
            });
        }
        if(q) {
            if(url !== '&') url += '&';
            url += `name_contains=${q}`;
            this.setState({
                q: q
            })
        }
        const total = await axios.get(`${API.CMS}/offers/count?${url}`);
        this.setState({
            total: total.data
        });
        if(total.data > this.state.limit) {
            this.setState({
                pagination: total.data / this.state.limit
            })
            if(page) {
                if(page > this.state.total) {
                    page = this.state.pagination;
                    let s = '';
                    if(this.state.q) {
                        s = `q=${this.state.q}`;
                    }
                    this.props.history.push({
                        search: `?${s}page=${page}`
                    }); 
                };
                start = this.state.limit * page - 1;
                this.setState({
                    page: parseInt(page)
                })
            }
        }
        const offers = await axios.get(`${API.CMS}/offers?_sort=createdAt:DESC&_start=${start}&status=published&_limit=${this.state.limit}${url}`);
        if(!offers.data) offers.data = [];
        this.setState({
            offers: offers.data
        })
    }
    handlePageChange = (page) => {
        let s = '';
        if(this.state.q) {
            s = `q=${this.state.q}&`;
        }
        this.props.history.push({
            search: `?${s}page=${page}`
        }); 
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getOffers();
        }
    }

    render() { 
        return (
            <div>
                <PageTitle title={'جميع العروض'}/>
                <section className="section">
                    <div className="container">
                        <Filter city={this.state.city} category={this.state.category} search={this.state.q} history={this.props.history}/>
                        <div className="row">
                            {
                                this.state.offers.map((offer) => ( 
                                    <Offer offer={offer} key={offer.id}/>
                                ))
                            }
                        </div>
                        <div className="pagination text-center">
                            <ul className="pagination mb-0">
                                <li className="page-item"><button className="page-link"  aria-label="Previous" onClick={() => this.state.page > 1 ? this.handlePageChange(this.state.page - 1) : null}>السابق</button></li>
                                {_.times(this.state.pagination, (i) => (
                                    <li key={i} className={this.state.page === (i+1) ? 'page-item active' : 'page-item'}>
                                        <button className="page-link" onClick={() => this.handlePageChange(i+1)}>{i+1}</button>
                                    </li>
                                ))}
                                <li className="page-item"><button className="page-link"  aria-label="Next" onClick={() => this.state.page < this.state.total ? this.handlePageChange(this.state.page + 1) : null}>التالي</button></li>
                            </ul>
                        </div>
                    </div>
                </section>
                
                <br/><br/><br/><br/><br/></div>
        )
    }
};

export default Offers;
import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { API } from '../../config/api';
import {isMobile} from 'react-device-detect';
// import {Link} from 'react-router-dom';
 
const Header = ({currentUser, lang, mainmenu, authMenu, userMenu, logo, toggleMenu, showMenu}) => (
  <header id="topnav" className="defaultscroll sticky nav-sticky"> 
      <div className="container">
          <div>
            <Link to="/" className="logo"><img src={API.CMS + logo.url} height="69" alt="" /></Link> 
          </div>                 
          <div className="buy-button">
               <Link to="/app/new-offer" className="btn btn-success">{lang.add_offer}</Link> 
          </div>
          <div className="menu-extras">
              <div className="menu-item">
                  <button className={showMenu ? 'navbar-toggle open' : 'navbar-toggle'} onClick={toggleMenu}>
                      <div className="lines">
                          <span></span>
                          <span></span>
                          <span></span>
                      </div>
                  </button>
              </div>
          </div>
        
          <div id="navigation" className={showMenu ? 'block' : null}>
              <ul className="navigation-menu">
                  {
                      mainmenu.map((menu, idx) => (
                        <li key={idx}><Link to={menu.url}>{menu.title === lang[menu.title] ? lang[menu.title] : menu.title}</Link></li>
                      ))
                  }
                  {isMobile ?(<hr/>) : (<li><p className="m-0"> | </p></li>)}
                  {
                    currentUser ? 
                        userMenu.map((menu, idx) => (
                            <li key={idx}><Link to={menu.url}>{menu.title === lang[menu.title] ? lang[menu.title] : menu.title}</Link></li>
                        ))
                     : (
                        <>
                            {
                                authMenu.map((menu, idx) => (
                                    <li key={idx}><Link to={menu.url}>{menu.title === lang[menu.title] ? lang[menu.title] : menu.title}</Link></li>
                                ))
                            }
                        </>
                    )
                  }
                  {isMobile ?(<><br/><hr style={{margin: 0}}/></>) : null}
              </ul>
              <div className="buy-menu-btn d-none">
                <Link to="/app/new-offer" className="btn btn-success">{lang.add_offer}</Link>
              </div>
          </div>
      </div>
  </header>
);

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    lang: state.lang.languages,
    showMenu: state.menu.showMenu
});
  
export default connect(mapStateToProps)(Header);

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

 
import userReducer from './user/user.reducer';
import languagesReducer from './language/language.reducer';
import menuReducer from './menu/menu.reducer';
import siteInfoReducer from './site-info/SiteInfo.reducer';
import offerInfoReducer from './offer/offer.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
}

const rootReducer = combineReducers({
    user: userReducer, 
    lang: languagesReducer,
    menu: menuReducer,
    site: siteInfoReducer,
    offer: offerInfoReducer
});

export default persistReducer(persistConfig, rootReducer); 
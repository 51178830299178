import React from 'react';
import {Helmet} from "react-helmet";
import Banner from '../../../components/banner/banner.component';

const ContactusPage = () => (
    <div id="main"> 
        <Helmet>
            <meta charSet="utf-8" />
            <title>Automatically.ai | Contact Us</title>
            <link rel="canonical" href="https://automatically.ai/terms-of-use" />
        </Helmet>
        <Banner title={'Get in Touch'} />
        <div className="f-box">
            <div className="flex-split">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="f-box-single wow fadeInUp animated">
                                <div className="f-box-content"> 
                                    <br/>
                                    <h4 className="text-center">
                                        There are two ways to get in touch with us:
                                    </h4>
                                    <ul className="list">
                                        <li>Email: <a href="mailto:support@automatically.ai">support@automatically.ai</a> (SLA 24 hours)<br/><br/></li>
                                        <li>Create Support Tickets: <a target="_blank" rel="noopener noreferrer" href="https://automatically.freshdesk.com/support/tickets/new">Link</a> (SLA 24 hours)<br/><br/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ContactusPage;
import React from 'react';
import axios from 'axios';
import { setCurrentUser, setCurrentUserToken } from '../../../redux/user/user.actions';
import { connect } from 'react-redux';
import { API } from '../../../config/api';
import { Redirect } from 'react-router-dom';

class ForgotPassword extends React.Component {
    state= {
        message: undefined,
        redirect: false,
        onSubmit: false,
        code: '',
        password: '',
        passwordConfirmation: ''
    }
    componentDidMount() {
        const code = this.props.match.params.code;
        this.setState({
            code: code
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const _this = this;
        const { code, password, passwordConfirmation } = this.state;
        const { setCurrentUser, setCurrentUserToken } = this.props;

        this.setState({
            onSubmit: true
        });

        axios.post(`${API.CMS}/auth/reset-password`, {
            code: code,
            password: password,
            passwordConfirmation: passwordConfirmation
          })
          .then(function (response) {
            setCurrentUser(response.data.user);
            setCurrentUserToken(response.data.jwt);
            axios.interceptors.request.use(
                function (config) {
                    config.headers.Authorization = 'Bearer ' + response.data.jwt;
                    return config;
                },
                error => {
                    Promise.reject(error);
                    return;
                }
            );
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.jwt;
        })
        .catch(function (error) {
            _this.setState({
                redirect: true
            })
        });
    }

    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({ [name]: value });
    }

    render() {
        const { password, passwordConfirmation, message, onSubmit, redirect } = this.state;
        const { lang } = this.props;
        return (
            <section className="bg-home bg-circle-gradiant d-flex align-items-center">
                { redirect ? (<Redirect push to="/app/profile"/>) : null }
                <div className="bg-overlay bg-overlay-white"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8">
                            <div className="card login-page bg-white shadow rounded border-0">
                                <div className="card-body">
                                    <h4 className="card-title text-center">{lang.reset_password}</h4>
                                    {
                                        message &&  
                                        <div className={`alert alert-${message.type} alert-dismissible fade show mb-0`}>
                                            {message.text}
                                        </div>
                                    }
                                        <form className="login-form mt-4" onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group position-relative">
                                                        <label>{lang.password} <span className="text-danger">*</span></label>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                                        <input
                                                            type="password"
                                                            className="form-control pl-5"
                                                            placeholder={lang.password}
                                                            name="password"
                                                            value={password}
                                                            onChange={this.handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group position-relative">
                                                        <label>{lang.confirm_password} <span className="text-danger">*</span></label>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                                        <input
                                                            type="password"
                                                            className="form-control pl-5"
                                                            placeholder={lang.confirm_password}
                                                            name="passwordConfirmation"
                                                            value={passwordConfirmation}
                                                            onChange={this.handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mb-0">
                                                    <button className="btn btn-primary btn-block" type="submit" disabled={!password || password !== passwordConfirmation || onSubmit}>{lang.submit}</button>
                                                </div>
                                            </div>
                                        </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => ({
    lang: state.lang.languages
});

const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user)),
    setCurrentUserToken: token => dispatch(setCurrentUserToken(token))
});
 
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
import React from 'react';

const Banner = (props) => (
    <div className="hero-home" style={{ padding: "120px 0 80px 0"}}>
        <div className="container">
            <div className="row text-center">
                <div className="col-sm-12">
                <div className="intro-blocks">
                    <h1>{props.title}</h1>
                </div>
                </div>
            </div>
        </div>
    </div> 
);

export default Banner;
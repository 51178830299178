import React from 'react';
import Filter from '../../../../../components/filter/filter.component';
import Offer from '../../../../../components/offer/offer.component';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const Services = (props) => (
    <section className="section">
        <div className="container">
            <Filter  history={props.history}/>
            <div className="row">
                {
                    props.offers.map((offer, idx) => (
                        <Offer offer={offer} key={offer.id} />
                    ))
                }
                {/* <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div className="card courses-desc overflow-hidden rounded shadow border-0">
              <div className="position-relative d-block overflow-hidden">
                  <img src="https://img.cyrkl.cz/images/product/1385/s1.jpg?1595771089" height="500px" className="img-fluid rounded-top mx-auto" alt="" />
                  <div className="overlay-work"></div>
                  <a href="/" className="text-white h6 preview">عرض الإعلان <i className="mdi mdi-chevron-right"></i></a>
              </div>
              <div className="card-body">
                  <h5><a href="/" className="title text-dark">الخشب - المنصات التالفة ، وأجزاء المنصات .</a></h5>
                  <div className="fees d-flex justify-content-between">
                      <ul className="list-unstyled mb-0 numbers">
                          <li><i className="mdi mdi-school text-muted"></i> الكمية: 300</li>
                      </ul>
                      <h4><span className="h6"></span>بالاتفاق</h4>
                  </div>
              </div>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div className="card courses-desc overflow-hidden rounded shadow border-0">
              <div className="position-relative d-block overflow-hidden">
                  <img src="https://img.cyrkl.cz/images/product/1391/s1.jpg?1595881887" className="img-fluid rounded-top mx-auto" alt="" />
                  <div className="overlay-work"></div>
                  <a href="/" className="text-white h6 preview">عرض الإعلان <i className="mdi mdi-chevron-right"></i></a>
              </div>
              <div className="card-body">
                  <h5><a href="/" className="title text-dark">الخشب - المنصات التالفة ، وأجزاء المنصات .</a></h5>
                  <div className="fees d-flex justify-content-between">
                      <ul className="list-unstyled mb-0 numbers">
                          <li><i className="mdi mdi-school text-muted"></i> الكمية: 1000</li>
                          <li><i className="mdi mdi-truck text-muted"></i> توصيل</li>
                      </ul>
                      <h4><span className="h6"></span>بالاتفاق</h4>
                  </div>
              </div>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div className="card courses-desc overflow-hidden rounded shadow border-0">
              <div className="position-relative d-block overflow-hidden">
                  <img src="https://img.cyrkl.cz/images/product/1391/s1.jpg?1595881887" className="img-fluid rounded-top mx-auto" alt="" />
                  <div className="overlay-work"></div>
                  <a href="/" className="text-white h6 preview">عرض الإعلان <i className="mdi mdi-chevron-right"></i></a>
              </div>
              <div className="card-body">
                  <h5><a href="/" className="title text-dark">الخشب - المنصات التالفة ، وأجزاء المنصات .</a></h5>
                  <div className="fees d-flex justify-content-between">
                      <ul className="list-unstyled mb-0 numbers">
                          <li><i className="mdi mdi-school text-muted"></i> الكمية: 1000</li>
                          <li><i className="mdi mdi-truck text-muted"></i> توصيل</li>
                      </ul>
                      <h4><span className="h6"></span>بالاتفاق</h4>
                  </div>
              </div>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div className="card courses-desc overflow-hidden rounded shadow border-0">
              <div className="position-relative d-block overflow-hidden">
                  <img src="https://img.cyrkl.cz/images/product/1391/s1.jpg?1595881887" className="img-fluid rounded-top mx-auto" alt="" />
                  <div className="overlay-work"></div>
                  <a href="/" className="text-white h6 preview">عرض الإعلان <i className="mdi mdi-chevron-right"></i></a>
              </div>
              <div className="card-body">
                  <h5><a href="/" className="title text-dark">الخشب - المنصات التالفة ، وأجزاء المنصات .</a></h5>
                  <div className="fees d-flex justify-content-between">
                      <ul className="list-unstyled mb-0 numbers">
                          <li><i className="mdi mdi-school text-muted"></i> الكمية: 1000</li>
                          <li><i className="mdi mdi-truck text-muted"></i> توصيل</li>
                      </ul>
                      <h4><span className="h6"></span>بالاتفاق</h4>
                  </div>
              </div>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div className="card courses-desc overflow-hidden rounded shadow border-0">
              <div className="position-relative d-block overflow-hidden">
                  <img src="https://img.cyrkl.cz/images/product/1391/s1.jpg?1595881887" className="img-fluid rounded-top mx-auto" alt="" />
                  <div className="overlay-work"></div>
                  <a href="/" className="text-white h6 preview">عرض الإعلان <i className="mdi mdi-chevron-right"></i></a>
              </div>
              <div className="card-body">
                  <h5><a href="/" className="title text-dark">الخشب - المنصات التالفة ، وأجزاء المنصات .</a></h5>
                  <div className="fees d-flex justify-content-between">
                      <ul className="list-unstyled mb-0 numbers">
                          <li><i className="mdi mdi-school text-muted"></i> الكمية: 1000</li>
                          <li><i className="mdi mdi-truck text-muted"></i> توصيل</li>
                      </ul>
                      <h4><span className="h6"></span>بالاتفاق</h4>
                  </div>
              </div>
            </div>
        </div> */}
            </div>
            <div className="text-center mt-5">
                <Link to="/offers" className="btn btn-success mt-2 mr-2"><i className="mdi mdi-chevron-right"></i> {props.lang.show_all_offers}</Link>
            </div>
        </div>
    </section>
);

const mapStateToProps = state => ({
    lang: state.lang.languages
});
  
export default connect(mapStateToProps)(Services);
import React from 'react';
import axios from 'axios';
import marked from 'marked';
import { Redirect } from 'react-router-dom';
import parse from 'html-react-parser';
import PageTitle from '../../components/page-title/page-title.component';
import { API } from '../../config/api';

const INIT_STATE = {
    slug: '',
    onLoad: true,
    content: {
        Description: ''
    },
    notFound: false,
};

class SinglePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = INIT_STATE
    }

    getPageData = async () => {
        let slug = this.props.match.params.slug;
        const content = await axios.get(`${API.CMS}/pages?slug=${slug}`);
        if(!content.data) {
            this.setState({
                notFound: true
            });
            return;
        }

        content.data[0].Description = marked(content.data[0].Description);

        this.setState({
            content: content.data[0],
            slug: slug,
            onLoad: false
        })
    }
    async componentDidMount() {
        this.getPageData();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let slug = this.props.match.params.slug;
        if (this.state.slug !== slug) {
            this.setState({slug: slug, onLoad: true}, () =>  this.getPageData());
        }
    }
    render() {
        const { notFound, content, onLoad } = this.state;
        return (
            <div>
                { notFound ? (<Redirect push to="/"/>) : null }
                {!onLoad && 
                    <>
                        <PageTitle title={content.Title} singlePage={true} />
                        <section className="section">
                            <div className="container single-page">
                                <div className="row">
                                    <div className="offset-lg-2 col-lg-8">
                                        <div className="card blog blog-detail border-0 shadow rounded">
                                            {content.Picture &&
                                                <img src={`${API.CMS}${content.Picture.url}`} className="img-fluid rounded-top" alt="" />
                                            }
                                            <div className="card-body content">
                                            <div className="text-muted mt-3">
                                                {parse(`${content.Description}`)}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                }
                {onLoad && 
                    <section className="section">
                    <div className="container single-page">
                        تحديث...
                        </div>
                    </section>
                }
            </div>
        )
    }
};

export default SinglePage;
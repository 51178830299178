import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import toggleMenu from '../redux/menu/menu.actions';

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        const { toggleMenu } = this.props;
        window.scrollTo(0, 0);
        toggleMenu(false);
      }
    }
  
    render() {
      return this.props.children
    }
  }

const mapDispatchToProps = dispatch => ({
  toggleMenu: show => dispatch(toggleMenu(show))
});

export default connect(null, mapDispatchToProps)(withRouter(ScrollToTop));  
import React from 'react';
import { Link } from 'react-router-dom';
import { API } from '../../../../../config/api';

const Banner = (props) => (
    <section className=" d-table w-100 bg-light">
            <div className="container">
                <div className="row mt-5 align-items-center">
                    <div className="col-md-7">
                        <div className="title-heading mr-lg-4">
                            <h4 className="display-4 font-weight-bold mb-3"> {props.content.banner.mainTitle} </h4>
                            <p className="text-muted para-desc mb-0">
                                {props.content.banner.description}
                            </p>
                            <div className="watch-video mt-4 pt-2">
                                <Link to={props.content.banner.link_01.url ? props.content.banner.link_01.url : '/'} className="btn btn-soft-primary mb-2">
                                    {props.content.banner.link_01.title}
                                </Link>
<Link to={props.content.banner.link_02.url ? props.content.banner.link_02.url : '/'} className="video-play-icon watch text-dark mb-2 ml-2">
<i className="mdi mdi-play play-icon-circle text-center d-inline-block mr-2 rounded-circle bg-light text-white title-dark position-relative play play-iconbar"></i>
{props.content.banner.link_02.title}
</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <img src={`${API.CMS}${props.content.banner.image.url}`} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
);

export default Banner;
import React from "react";
import axios from 'axios';
import { API } from "../../../config/api";
import { connect } from "react-redux";
import Offer from "../../../components/offer/offer.component";

class UserOffers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myOffers: [],
            activeAll: true,
            published: false,
            pending: false
        }
    }

    async componentDidMount() {
        const { currentUser } = this.props;
        let myOffers = await axios.get(API.CMS + `/offers?user.id=${currentUser.id}&_sort=createdAt:DESC`);
        if(!myOffers.data) myOffers.data = [];
        this.setState({
            myOffers: myOffers.data
        });
    }
    getOffers= async(status) => {
        const { currentUser } = this.props;
        let request = '';
        this.setState({
            myOffers: []
        });
        let activeAll = false, published= false, pending= false;
        if(status === 'all') {
            activeAll = true;
            request = `?user=${currentUser.id}&_sort=createdAt:DESC`;
        } else {
            request = `?status=${status}&user=${currentUser.id}&_sort=createdAt:DESC`;
            switch(status) {
                case 'published':
                    activeAll = false;
                    published= true;
                    pending= false;
                    break;
                case 'pending': 
                    activeAll = false;
                    published= false;
                    pending= true;
                    break;
                default:
                    activeAll = true;
                    published= false;
                    pending= false; 
            }
        }

        this.setState({
            activeAll: activeAll,
            published: published,
            pending: pending
        });
        let myOffers = await axios.get(API.CMS + `/offers${request}`);
        if(myOffers.data && myOffers.data[0]) {
            this.setState({
                myOffers: myOffers.data
            });
        }
    }
    render() {
        const {
            myOffers,
            activeAll,
            published,
            pending
        } = this.state;
        const { lang } = this.props;
        const btnClasses = "btn btn-sm mt-2 mr-2";
        return (
            <div>
                <div >
                    <button onClick={()=>this.getOffers('all')} className={activeAll ? `active btn-soft-primary ${btnClasses}`: `btn-light ${btnClasses}`}>{lang.all_offers}</button>
                    <button onClick={()=>this.getOffers('published')} className={published ? `active btn-soft-success ${btnClasses}`: `btn-light ${btnClasses}`} >{lang.published_offers}</button>
                    <button onClick={()=>this.getOffers('pending')} className={pending ? `active btn-soft-warning ${btnClasses}`: `btn-light ${btnClasses}`} >{lang.pending_offers}</button>
                </div>
                <div className="row">
                    {
                        myOffers.map((offer) => ( 
                            <Offer offer={offer} key={offer.id} styles={"col-lg-12"} />
                        ))
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    lang: state.lang.languages
});

export default connect(mapStateToProps)(UserOffers);

import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const HowItWorks = (props) => {
  return (
    <section className="section bg-light">
      <div className="container">
          <div className="row justify-content-center">
              <div className="col-12 ">
                  <div className="section-title mb-4 pb-2">
                      <h4 className="title mb-4">{props.content.title}</h4>
                      <div className="text-muted mx-auto">
                        {parse(props.content.description, {
                            trim: true
                        })
                        }
                      </div>
                  </div>
              </div>
              <Link to={props.content.link.url} className="btn btn-outline-primary mt-2"><i className="mdi mdi-arrow-right"></i> {props.content.link.title}</Link>
          </div>
      </div>
    </section>
  )
};

export default HowItWorks;
import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import { connect } from "react-redux";

import Signin from '../../pages/public/Signin/signin.component';
import Homepage from '../../pages/public/homepage/homepage.component';
import PrivacyPolicyPage from '../../pages/public/privacy-policy/privacy-policy.component';
import Guide from '../../pages/public/guide/guide';
import TermsOfUsePage from '../../pages/public/terms-of-use/terms-of-use.component';
import ContactusPage from '../../pages/public/contactus/contactus.component';
import Offers from '../../pages/public/offers/offers.component';
import OfferDetails from '../../pages/public/offers/offer-details.component';
import SinglePage from '../../pages/single-page/SinglePage.component';
import Registration from '../../pages/public/registration/Registration.component';
import EmailConfirmation from '../../pages/public/email-confirmation/email-confirmation';
import ForgotPassword from '../../pages/public/forgot-password/forgot-password.component';

class PublicLayout extends React.Component {
    render() {
        const NotFoundRedirect = () => <Redirect to='/' />
        const {token, currentUser} = this.props;
        return (
        <div>
            <Switch>
                <Route exact path="/" component={Homepage} />
                <Route exact path='/home' component={Homepage} />
                <Route exact path='/offers' component={Offers} />
                <Route exact path='/offers/:category?/:city?' component={Offers} />
                <Route exact path='/offer/:id/:name' component={OfferDetails} />
                <Route exact path='/privacy-policy' component={PrivacyPolicyPage} />
                <Route exact path='/guide' component={Guide} />
                <Route exact path='/page/:slug' component={SinglePage} />
                <Route
                    exact
                    path="/signin"
                    render={() =>
                        (token && currentUser) ? (
                            <Redirect to="/app/profile" />
                        ) : (
                            <Signin />
                        )
                    }
                />
                <Route
                    exact
                    path="/registration"
                    render={() =>
                        this.props.token ? (
                            <Redirect to="/app/profile" />
                        ) : (
                            <Registration />
                        )
                    }
                />
                <Route
                    exact
                    path="/email-confirmation/:token"
                    key={this.props.location.key}
                    render={({ 
                        match 
                    }) => (
                        this.props.token ? (
                            <Redirect to="/app/profile" />
                        ) : (
                            <EmailConfirmation key={this.props.location.key} match={match}/>
                        )
                    )}
                />
                <Route
                    exact
                    path="/forgot-password/:code"
                    key={this.props.location.key}
                    render={({ 
                        match 
                    }) => (
                        (this.props.token && this.props.user) ? (
                            <Redirect to="/app/profile" />
                        ) : (
                            <ForgotPassword key={this.props.location.key} match={match}/>
                        )
                    )}
                />
                <Route exact path='/terms-of-use' component={TermsOfUsePage} />
                <Route exact path='/contact' component={ContactusPage} />
                <Route component={NotFoundRedirect} />
            </Switch>
        </div>
      );
    }
  }
  const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    token: state.user.token
}); 
  
export default connect(mapStateToProps)(PublicLayout);
  
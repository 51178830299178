import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import {Helmet} from "react-helmet";
import axios from 'axios';

import PrivateLayout from './layouts/PrivateLayout/PrivateLayout';
import PublicLayout from './layouts/PublicLayout/PublicLayout';

import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';
import setLanguages from './redux/language/language.actions';
import { API } from './config/api';
import toggleMenu from './redux/menu/menu.actions';
import { setSiteInfo } from './redux/site-info/SiteInfo.actions';

import ReactGA from 'react-ga';
import { setOfferContactInfo } from './redux/offer/offer.actions';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header: [],
      isloaded: false,
      isAuth: false,
      footer: []
    } 
  }
  async componentDidMount() { 
    const { token, setLanguages, setSiteInfo, setOfferContactInfo, history } = this.props;
    history.listen(location => {
      ReactGA.initialize('UA-182141995-1');
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
    if (token) {
      axios.interceptors.request.use(
        function(config) {
          config.headers.Authorization = 'Bearer ' + token;
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    const mainmenu = await axios.get(API.CMS + '/header');
    const language = await axios.get(API.CMS+ '/languages');
    const footer = await axios.get(API.CMS+ '/footer');
    const offerContactInfo = await axios.get(API.CMS + '/offer-details');
    let offerContactInfoResponse = offerContactInfo.data;
    let offerContactInfoData = {
      email: offerContactInfoResponse.email,
      phone: offerContactInfoResponse.phone,
      lang: offerContactInfoResponse.languageKey.key
    };
    setOfferContactInfo(offerContactInfoData);
    let langs = {};
    language.data.map((lang) => langs[lang.key] = lang.lang.ar); 
    setLanguages(langs);
    setSiteInfo(mainmenu.data);
    this.setState({
      isloaded: true,
      header: mainmenu.data,
      footer: footer.data
    });
  }
  toggleMenu = () => {
    const { toggleMenu } = this.props;
    toggleMenu();
  }
  render() {
    const { header, footer } = this.state;
    const { site, token, currentUser } = this.props;
    return this.state.isloaded ? (
        <div className="wrapper">
          <Helmet>
            <title>{header.siteName}</title>
            <meta name="keywords" content={site.keywords} />
            <meta name="description" content={site.siteDescription} />
            <meta property="og:title" content={header.slogan} />
            <meta property="og:type" content="" />
            <meta property="og:url" content="https://dawerha.ps" />
            <meta property="og:image" content="https://dawerha.ps/logo.png" />
            <meta property="og:site_name" content={header.siteName} />
            <meta property="og:description" content={site.slogan} />
          </Helmet>
          <Header
            isAuth={this.props.currentStyle}
            mainmenu={header.mainmenu}
            authMenu={header.authMenu}
            userMenu={header.userMenu}
            logo={header.logo}
            toggleMenu={this.toggleMenu}
          />
          <h1>&nbsp;</h1>
          <Switch>
            <Route path="/app" render={ () => (token !== null && currentUser !== null) ? <PrivateLayout /> : <Redirect to='/signin' /> } />
            <Route path="/" component={PublicLayout} />
          </Switch>
          <Footer footer={footer}/>
        </div>
    ) : (<div id="main">Loading...</div>);
  }
}
 

const mapStateToProps = state => ({
  site: state.site.siteInfo,
  currentUser: state.user.currentUser,
  toke: state.user.token
});

const mapDispatchToProps = dispatch => ({
  setLanguages: lang => dispatch(setLanguages(lang)),
  setSiteInfo: info => dispatch(setSiteInfo(info)),
  toggleMenu: () => dispatch(toggleMenu()),
  setOfferContactInfo: info => dispatch(setOfferContactInfo(info))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));

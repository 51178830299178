import React from 'react';
import axios from 'axios';
import { API } from '../../config/api'; 
import { connect } from 'react-redux';

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: [],
            categories: [],
            category: 'all',
            city: 'all',
            search: ''
        }
    }
    async componentDidMount() {
        let
            category = this.props.category,
            city = this.props.city,
            search = this.props.search
            ;
        if (category) this.setState({ category: category });
        if (city) this.setState({ city: city });
        if (search) this.setState({ search: search });
        const cities = await axios.get(API.CMS + '/cities');
        this.setState({
            cities: cities.data
        })
        const categories = await axios.get(API.CMS + '/categories');
        this.setState({
            categories: categories.data
        })
    }
    handleChange = (event) => {
        const { value, name } = event.target;
        this.setState({ [name]: value, search: '' }, () => {
            let link = `/offers/${this.state.category}/${this.state.city}`;
            this.redirectToLink(link);
            return;
        });
    }
    redirectToLink = (link) => {
        this.props.history.push(link);
        return;
    }
    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({ [name]: value });
    }
    handleBlur = (event) => {
        event.preventDefault();
        const { value } = event.target;
        if (value === this.props.search) return;
        let link = `/offers/${this.state.category}/${this.state.city}?q=${value}`;
        window.location = link;
    }
    handleSubmit = (event) => {
        event.preventDefault(); 
        if (!this.state.search && this.props.search) {
            let link = `/offers/${this.state.category}/${this.state.city}`;
            this.redirectToLink(link);
            return;
        }
        //if (this.state.search === this.props.search || !this.state.search) return;
        let link = `/offers/${this.state.category}/${this.state.city}?q=${this.state.search}`;
        this.redirectToLink(link);
        return;
    }

    componentDidUpdate(prevProps) {
        if (this.props.city !== prevProps.city) {
            this.setState({
                city: this.props.city
            })
        }
        if (this.props.category !== prevProps.category) {
            this.setState({
                category: this.props.category
            })
        }
        if (this.props.search !== prevProps.search) {
            this.setState({
                search: this.props.search
            })
        }
    }
    render() {
        const { lang } = this.props;
        return (
            <div className="row align-items-center">
                <div className="col-md-3">
                    <div className="section-title">
                        <h5 className="mb-0">{lang.latest_offers_added}</h5>
                    </div>
                </div>
                <div className="col-lg-4 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="widget">
                        <div id="search2" className="widget-search mb-0">
                            <form id="searchform" className="searchform" onSubmit={this.handleSubmit}>
                                <div>
                                    <input
                                        type="text"
                                        className="border rounded"
                                        name="search"
                                        value={this.state.search}
                                        onChange={this.handleInputChange}
                                        placeholder={`${lang.looking_for}...`}
                                    />
                                    <input type="submit" id="searchsubmit" value="Search" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="form custom-form">
                        <div className="form-group mb-0">
                            <select
                                className="form-control custom-select"
                                id="Sortbylist-job"
                                value={this.state.category}
                                onChange={this.handleChange}
                                name="category"
                            >
                                <option value={'all'}>{lang.all_categories}</option>
                                {this.state.categories.map((category, idx) => (
                                    <option key={category.slug} value={category.slug}>{category.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="form custom-form">
                        <div className="form-group mb-0">
                            <select
                                className="form-control custom-select"
                                id="Sortbylist-job"
                                value={this.state.city}
                                onChange={this.handleChange}
                                name="city"
                            >
                                <option value={'all'}>{lang.all_cities}</option>
                                {this.state.cities.map((city, idx) => (
                                    <option key={city.slug} value={city.slug}>{city.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};


const mapStateToProps = state => ({
    lang: state.lang.languages
});
  
export default connect(mapStateToProps)(Filter);
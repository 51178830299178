import React from "react";
import { API } from "../../config/api";

const Account = ({
  currentUser,
  lang,
  imagePreviewUrl,
  onChangeFile,
  handleInputChange,
  cities,
  handleSubmit,
  button,
  isNew,
  error
}) => (
  <form className="login-form mt-4" onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-lg-12">
        <div className="form-group position-relative text-center">
          <div className="image-upload custom-file">
            <div>
              {currentUser.company_logo && (
                <img
                  src={
                    imagePreviewUrl
                      ? imagePreviewUrl
                      : `${API.CMS}${currentUser.company_logo.url}`
                  }
                  alt=""
                  width="130px"
                  className="avatar avatar-large rounded-circle shadow d-block mx-auto"
                />
              )}
              {!currentUser.company_logo && (
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  alt=""
                  width="130px"
                  className="avatar avatar-large rounded-circle shadow d-block mx-auto"
                />
              )}
            </div>
            <input
              type="file"
              name="Company_logo"
              id="Company_logo"
              className="custom-file-input"
              onChange={onChangeFile}
              placeholder="Upload"
              accept="image/x-png,image/gif,image/jpeg"
            />
            <button className="btn btn-sm btn-outline-primary mt-1">
              {lang.select_company_logo}
            </button>
          </div>
        </div>
      </div>

      <div className="col-lg-6">
        <div className="form-group position-relative">
          <label htmlFor="firstname">
            {lang.firstname} <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control pl-5"
            placeholder={lang.firstname}
            id="firstname"
            name="firstname"
            value={currentUser.firstname}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>

      <div className="col-lg-6">
        <div className="form-group position-relative">
          <label htmlFor="lastname">
            {lang.lastname} <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control pl-5"
            placeholder={lang.lastname}
            id="lastname"
            name="lastname"
            value={currentUser.lastname}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>
      {isNew && (
        <>
          <div className="col-lg-6">
            <div className="form-group position-relative">
              <label htmlFor="password">
                {lang.password} <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control pl-5"
                placeholder={lang.password}
                id="password"
                name="password"
                value={currentUser.password}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group position-relative">
              <label htmlFor="confirm_password">
                {lang.confirm_password} <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control pl-5"
                placeholder={lang.confirm_password}
                id="confirm_password"
                name="confirm_password"
                value={currentUser.confirm_password}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        </>
      )}
      <div className="col-lg-6">
        <div className="form-group position-relative">
          <label htmlFor="email">
            {lang.email} <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control pl-5"
            placeholder={lang.email}
            id="email"
            name="email"
            value={currentUser.email}
            onChange={handleInputChange}
            style={{ textAlign: "right", direction: "ltr" }}
            required
          />
        </div>
      </div>

      <div className="col-lg-6">
        <div className="form-group position-relative">
          <label htmlFor="phone">
            {lang.phone} <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control pl-5"
            placeholder={lang.phone}
            id="phone"
            name="phone"
            value={currentUser.phone}
            onChange={handleInputChange}
            style={{ textAlign: "right", direction: "ltr" }}
            required
          />
        </div>
      </div>

      <div className="col-lg-6">
        <div className="form-group position-relative">
          <label htmlFor="company_name">
            {lang.company_name} <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control pl-5"
            placeholder={lang.company_name}
            id="company_name"
            name="company_name"
            value={currentUser.company_name}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>

      <div className="col-lg-6">
        <div className="form-group position-relative">
          <label htmlFor="city">
            {lang.city} <span className="text-danger">*</span>
          </label>
          <select
            className="form-control custom-select"
            id="city"
            value={currentUser.city.id}
            onChange={handleInputChange}
            name="city"
          >
            <option value={"all"}>{lang.all_cities}</option>
            {cities.map((city, idx) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group position-relative">
          <label htmlFor="address">
            {lang.address} <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control pl-5"
            placeholder={lang.address}
            id="address"
            name="address"
            value={currentUser.address}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group position-relative">
          <label htmlFor="company_about">
            {lang.about_company} <span className="text-danger">*</span>
          </label>
          <textarea
            type="text"
            className="form-control pl-5"
            placeholder={lang.about_company}
            id="company_about"
            name="company_about"
            value={currentUser.company_about}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>
      </div>

      <div className="col-lg-12 mb-0 mt-4">
        {error && 
          <div className="alert alert-danger alert-dismissible fade show mb-4">
              {error.message} 
          </div>
        }
        <button type="submit" className="btn btn-primary btn-block">
          {button}
        </button>
      </div>
    </div>
  </form>
);

export default Account;

import { UserActionTypes } from './user.types';
export const setCurrentUser = user => ({
    type: UserActionTypes.SET_CURRENT_USER,
    payload: user
});
export const setCurrentUserToken = token => ({
    type: UserActionTypes.SET_CURRENT_USER_TOKEN,
    payload: token
});
export const updateUserProfile = () => ({
    type: UserActionTypes.UPDATE_CURRENT_USER
});


import SiteInfoTypes from './SiteInfo.types';

const INITIAL_STATE = {
  siteInfo: [],
};


const siteInfoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SiteInfoTypes.SET_SITE_INFO:
          let info = action.payload;
          let keywords = info.keywords;
          let newKeywords;
          keywords.forEach((keyword, key) => {
                if(keyword.keyword) {
                    if(key === 0) {
                        newKeywords= keyword.keyword;
                    } else {
                        newKeywords += keyword.keyword;
                    }
                    if(key+2 < keywords.length) {
                        newKeywords +=  ', ';
                    }
                }
          });
          info.keywords = newKeywords;
        return {
            ...state,
            siteInfo: info,
        }
        default: 
            return state;
    }
}

export default siteInfoReducer;
import OfferTypes from './offer.types';

const INITIAL_STATE = {
  contact: {
      email: '',
      phone: '',
      languageKey: ''
  },
};


const offerInfoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case OfferTypes.SET_OFFER_CONTACT_INFO:
        if(action.payload === state.showMenu ) return state;
        return {
            ...state,
            contact: action.payload
        }
        default: 
            return state;
    }
}

export default offerInfoReducer;
import React from 'react';
import Banner from './components/banner/banner.component';
import Services from './components/services/services';
import HowItWorks from './components/how-it-works/how-it-works.component';
// import Pricing from './components/pricing/pricing.component';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { API } from '../../../config/api';
import { connect } from 'react-redux';

class Homepage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: {
                banner: {
                    mainTitle: '',
                    description: '',
                    link_01: [],
                    link_02: [],
                    image: []
                },
                section: {
                    title: '',
                    description: '',
                    link: []
                }
            },
            offers: [],
            cities: [],
            categories: [],
            howItWorks: []
        }
    }
    async componentDidMount() {
        const offers = await axios.get(API.CMS + '/offers?_limit=6&status=published&_sort=createdAt:DESC');
        const content = await axios.get(API.CMS + '/homepage');
        if(content.data.section) {
            content.data.section.description = content.data.section.description.replace('\n', '<br/><br/>');
        }
        if(!offers.data)  offers.data = [];
        this.setState({
            content: content.data,
            offers: offers.data
        }) 
    }
    render() {
        const { content, offers } = this.state;
        const { site } = this.props;
        return (
            <div id="main">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{site.siteName} - {site.slogan} | الصفحة الرئيسية</title>
                </Helmet>
                {content.banner.image.url && <Banner title={'test'} content={content}/>}
                <Services offers={offers}  history={this.props.history}/>
                {content.section.title && <HowItWorks content={content.section}/>}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    site: state.site.siteInfo,
});

export default connect(mapStateToProps)(Homepage);
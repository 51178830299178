import React from 'react';
import axios from 'axios';
import PageTitle from '../../../components/page-title/page-title.component';
import { API } from '../../../config/api';
import { connect } from 'react-redux';

class OfferDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offer: null
        }
    }

    async componentDidMount() {
        let id = this.props.match.params.id;
        let offer = await axios.get(`${API.CMS}/offers?id=${id}`);
        if (!offer.data[0]) {
            window.location = '/offers';
            return;
        }  
        this.setState({
            offer: offer.data[0]
        })
    }

    render() {
        const { offer } = this.state;
        const { lang, offerContactInfo } = this.props;
        return (
            <div>
                {
                    offer ? (
                        <>
                            <PageTitle name={offer.name} cat={offer.category} />
                            <section className="section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="slider slider-for">
                                                <div><img src={`${API.CMS}${offer.photo[0].url}`} className="img-fluid rounded" alt="" /></div>
                                            </div>
                                        </div>
                                        <div className="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <div className="section-title ml-md-4">
                                                <h4 className="title">{offer.name}</h4>
                                                <h5 className="mt-5 m-0 p-0">{lang.address}:</h5>
                                                <p className="mt-0 pt-0">{offer.city.name}</p>
                                                <p className="text-muted">{offer.description}</p> 
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <p>{lang.price}:</p>
                                                        {offer.priceByAgreement ? (
                                                            <h4><span className="h6"></span>{lang.by_agreement}</h4>
                                                        ) : (
                                                                <h4><span className="h6"></span>
                                                                    {offer.price}₪&nbsp;
                                                                    <span className="badge badge-pill badge-outline-light">{offer.pricePerUnit ? (<small>{lang.per_unit}</small>) : (<small>{lang.for_total}</small>)}</span>
                                                                </h4>
                                                            )}
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <p>{lang.for_total}:</p>
                                                        <h5 className="text-muted">{offer.amount} {offer.unit.name}</h5>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <ul className="list-unstyled mb-0 numbers">
                                                            {offer.transport ? (<li><i className="mdi mdi-truck text-muted"></i> {lang.with_delivery}</li>) : null}
                                                        </ul>
                                                    </div>
                                                </div>

                                                <hr className="mt-4 pt-2"/>
                                                <div className="mt-4 pt-2 text-center">
                                                    <div className="features">
                                                        <div className="image position-relative d-inline-block">
                                                            <img src="/assets/images/user.svg" className="avatar avatar-small" alt="" />
                                                        </div>
                                                        <div className="content mt-4">
                                                            <h4 className="title-2">{lang.contact_with_us}</h4>
                                                            <p className="text-muted mb-2">{lang[offerContactInfo.lang]}</p>
                                                            {offerContactInfo.phone &&
                                                                <>
                                                                    <a dir="ltr" href={`tel:${offerContactInfo.phone}`} className="text-primary">{offerContactInfo.phone}</a>
                                                                    {offerContactInfo.email && <p className=" pt-2"> - {lang.or} - </p>}
                                                                </>
                                                            }                                                            
                                                        </div>
                                                        {
                                                            offerContactInfo.email &&
                                                            <div className="mt-2">
                                                                <a href={`mailto:${offerContactInfo.email}`} className="btn btn-soft-primary mb-2"><i className="mdi mdi-email"></i> {lang.send_message}</a>
                                                            </div>
                                                        }
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>

                    ) : (
                            <div style={{ minHeight: '600px' }}>Loading...</div>
                        )
                }
            </div>
        )
    }
};

const mapStateToProps = state => ({
    lang: state.lang.languages,
    offerContactInfo: state.offer.contact
});

export default connect(mapStateToProps)(OfferDetails);
import React from "react";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";
import { API } from "../../../config/api";
import axios, { post } from "axios";
import Account from "../../../components/account/Account.component";
// import errorResponseHandler from "../../../errorHandler";
import { Link, Redirect } from "react-router-dom";

class Registration extends React.Component {
  notificationSystem = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      companyLogo: null,
      imagePreviewUrl: [],
      submitted: false,
      error: undefined,
      currentUser: {
        username: "",
        password: "",
        confirm_password: "",
        email: "",
        firstname: "",
        lastname: "",
        phone: "",
        company_name: "",
        address: "",
        city: "",
        company_about: "",
        company_logo: "",
      },
      cities: [],
    };
  }
  fileUpload = (file) => {
    const url = API.CMS + "/upload";
    const formData = new FormData();
    formData.append("files", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return post(url, formData, config);
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;
    let newArray = this.state.currentUser;
    newArray = { ...newArray, [name]: value };
    this.setState(
      {
        currentUser: newArray,
      });
  };

  onChangeFile = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) return [];
    reader.onloadend = () => {
      this.setState(
        {
          companyLogo: file,
          imagePreviewUrl: reader.result,
        });
    };

    reader.readAsDataURL(file);
    this.setState({ companyLogo: e.target.files[0] }, () => {
      this.fileUpload(this.state.companyLogo).then((response) => {
        if (response.data && response.data[0]) {
          let newArray = this.state.currentUser;
          let company_logo = response.data;
          newArray = { ...newArray, company_logo };
          this.setState(
            {
              currentUser: newArray,
            });
        }
      });
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const _this = this;
    const url = API.CMS + `/auth/local/register`;
    const { currentUser } = this.state;
    const { lang } = this.props;
    const notification = this.notificationSystem.current;
    let company_logo = null;

    if (currentUser.password !== currentUser.confirm_password) {
      notification.addNotification({
        message: "كلمة المرور وتأكيد كلمة المرور غير متساويين!",
        position: "tc",
        dismissible: "click",
        level: "error",
      });
      return;
    }

    if (currentUser.company_logo.length) {
      company_logo = currentUser.company_logo[0].id;
    }

    const sendData = {
      username: currentUser.email,
      password: currentUser.password,
      email: currentUser.email,
      firstname: currentUser.firstname,
      lastname: currentUser.lastname,
      phone: currentUser.phone,
      company_name: currentUser.company_name,
      address: currentUser.address,
      city: currentUser.city,
      company_about: currentUser.company_about,
      company_logo: company_logo,
    };
    const options = {
      method: "POST",
      url: url,
      data: sendData,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: sendData,
    };
    return axios(options).then(async (response) => {
      // axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.jwt;
      // await axios.get(API.CMS + '/users/me').then((user) => {
      //    setCurrentUser(user.data);
      //    setCurrentUserToken(response.data.jwt);
      // })
      this.setState({
        submitted: true
      })
      notification.addNotification({
        message: "تم انشاء حساب جديد بنجاح",
        position: "tr",
        dismissible: "click",
        level: "success",
      });
      //window.location.reload();
    })
    .catch(error => {
          _this.setState({
            error: {
              message: lang.message_email_already_registered
            }
          })
    });
  };

  async componentDidMount() {
    let city = await axios.get(API.CMS + `/cities`);
    this.setState({
      cities: city.data,
    });
  }

  render() {
    const { imagePreviewUrl, currentUser, cities, submitted, error } = this.state;
    const { lang } = this.props;
    return (
      <div>
        {currentUser.id ? <Redirect to="/app/profile" /> : null}
        <NotificationSystem ref={this.notificationSystem} />
        <section className="bg-home bg-circle-gradiant d-flex align-items-center">
          <div className="bg-overlay bg-overlay-white"></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-8">
                <div className="card login-page bg-white shadow rounded border-0">
                  {!submitted && (
                    <div className="card-body">
                      <h4 className="card-title text-center">
                        {lang.register}
                      </h4>
                      <Account
                        currentUser={currentUser}
                        error={error}
                        lang={lang}
                        imagePreviewUrl={imagePreviewUrl}
                        onChangeFile={this.onChangeFile}
                        handleInputChange={this.handleInputChange}
                        cities={cities}
                        handleSubmit={this.handleSubmit}
                        button={lang.register}
                        isNew={true}
                      />
                    </div>
                  )}
                  {submitted && (
                    <div className="card-body">
                      <h4 className="card-title text-center">
                        تم انشاء الحساب بنجاح
                      </h4>
                      <div className="text-center">
                        <br/>
                      <p className="text-muted h5 font-weight-normal">
                        لقد أرسلنا بريدًا إلكترونيًا به رابط تأكيد إلى عنوان
                        بريدك الإلكتروني.
                      </p>
                        <br/>
                        <Link to="/signin" className="btn btn-primary mb-2">
                          {lang.signin}
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.lang.languages,
});

export default connect(mapStateToProps)(Registration);

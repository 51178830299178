import axios from 'axios';
import { API } from "../config/api";
class UploadFilesService {
  upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("files", file);

    return axios.post( API.CMS + "/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return [];
    // return axios.get("/files");
  }
}

export default new UploadFilesService();
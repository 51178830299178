import React from 'react';
import {Helmet} from "react-helmet";
import Banner from '../../../components/banner/banner.component';

const TermsOfUsePage = () => (
    <div id="main">
    <Helmet>
        <meta charSet="utf-8" />
        <title>Automatically.ai | Terms of Use</title>
        <link rel="canonical" href="https://automatically.ai/terms-of-use" />
    </Helmet>
        <Banner title={'Terms of Use'} />
        <div className="container">
            <div className="about">
                <div className="jss208">
   <br/>
                    <div className="jss209">
                    <p>The following terms and conditions govern all access to and use of the Automatically.ai.io web applications, and service (together, the &ldquo;Automatically.ai Service&rdquo;) including all content, services and products available at or through the Automatically.ai Service.</p>
<p>The Automatically.ai Service is owned and operated by Automatically.ai (&ldquo;Automatically.ai&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;us&rdquo;). The Automatically.ai Service is offered subject to your acceptance of the terms and conditions contained herein and all other operating rules, policies and procedures that may be published from time to time on the Website (collectively, the &ldquo;Agreement&rdquo;).</p>
<p>Please read the Agreement carefully before accessing or using the Automatically.ai Service. By accessing or using any part of the Automatically.ai Service, you agree to become bound by the terms and conditions of the Agreement. If you do not agree to all the terms and conditions of the Agreement, then you may not access or use the Automatically.ai Service.</p>
<br/><ol className="list">
<li><h3> Description of the Automatically.ai Service</h3></li>
</ol>
<p>The Automatically.ai service allows you to export recordings and transcripts from your account on connected platforms (Zoom, Teams, Workplace and others) to other content platforms (Google drive, Box, Dropbox etc.)</p>
<p>Automatically.ai may also, in the future, update current services and/or features or offer new services and/or features to the Automatically.ai Service (including, the release of new tools). Such updated or new features and/or services shall be subject to the terms and conditions of the Agreement.</p>
<p>Access to the Automatically.ai Service is permitted on a temporary basis. We reserve the right to modify or discontinue offering or updating the Automatically.ai Service at any time without notice.</p>
<br/><ol className="list" start="2">
<li><h3> No association with other platforms</h3></li>
</ol>
<p>There is no relationship between Automatically.ai and other collaboration and content platforms, other than Automatically.ai being a licensee and user of the third-party APIs for the purpose of providing the Automatically.ai Service.</p>
<br/><ol className="list" start="3">
<li><h3> Capacity</h3></li>
</ol>
<p>In order to use the Automatically.ai Service, you must be 18 years of age or older, or be 13 years of age or older and have your parent or guardian&rsquo;s consent to the Agreement. You must also have the power to enter into a binding contract with us and not be barred from doing so under any applicable laws.</p>
<br/><ol className="list" start="4">
<li><h3> Your Automatically.ai Account and Personal Data</h3></li>
</ol>
<p>This privacy policy will change over time. To use the Automatically.ai Service, you must first install a Automatically.ai connector into your account by signing into your respective collaboration platform account on the Website and following any prompts.</p>
<p>You may install a Automatically.ai connector in your personal capacity (in which case the terms &ldquo;you&rdquo; or &ldquo;your&rdquo; apply to yourself only) or on behalf of a company or other legal entity which you have the authority to bind (in which case the terms &ldquo;you&rdquo; or &ldquo;your&rdquo; shall refer to such entity and all permitted Members and Administrative Users (as defined in the Slack Terms of Service).</p>
<p>During the install process, your will be prompted to provide access to Automatically.ai connector to access your account data and content in these collaboration and content platforms. The processing of your personal data is subject to our&nbsp;Privacy Policy&nbsp;which is hereby incorporated by reference.</p>
<p>Automatically.ai does not have access to your user name or passwords and so does not store your credentials in the service. Automatically.ai does not store any of your recordings or chat content except for the meta data that is used to provide administrative reports.</p>
<p>You are fully responsible for all activities that occur under your Automatically.ai Account and any other actions taken in connection with it, whether by yourself or other users. You must immediately notify Automatically.ai of any unauthorized uses and users of your Automatically.ai Account or any other breaches of security. Automatically.ai will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.</p>
<br/><ol className="list" start="5">
<li><h3> Subscriptions</h3></li>
</ol>
<p>You may access and use the Automatically.ai Service on any of our subscription plans:</p>
<ol className="list">
<li>Free Service: This subscription plan allows you to create polls and make basic use of the Automatically.ai Service for free; or</li>
<li>Paid Subscriptions: Automatically.ai might introduce more advanced features on a paid subscription plan in the near future.</li>
</ol>
<p>You may only use the Automatically.ai Service within the limits of your subscription level. You may upgrade your subscription level. Automatically.ai reserves the right to update these terms as we introduce paid plans.</p>
<br/><ol className="list" start="6">
<li><h3> Intellectual Property</h3></li>
</ol>
<p>We are the owner or the licensee of all intellectual property rights in the Automatically.ai Service and in the material published on it. We grant you: a limited, non-exclusive, revocable license to make use of the Automatically.ai Service (excluding the Website) within the limits of the Subscription level you have purchased; and</p>
<p>This license shall terminate when the Agreement terminates in accordance with clause 12.</p>
<p>The Agreement does not transfer any of Automatically.ai&rsquo;s or any of Automatically.ai&rsquo;s licensors&rsquo; intellectual property to you. Title to such intellectual property will remain solely with Automatically.ai or Automatically.ai&rsquo;s licensors (as applicable).</p>
<p>All Automatically.ai trademarks, service marks, trade names, logos, domain names, and any other features of the Automatically.ai brand are the sole property of Automatically.ai. Your use of the Automatically.ai Service grants you no right or license to reproduce or otherwise use any of Automatically.ai&rsquo;s trademarks, service marks, trade names, logos, domain names or any other features of the Automatically.ai brand, whether for commercial or non-commercial use.</p>
<br/><ol className="list" start="7">
<li><h3> Third Party Sites</h3></li>
</ol>
<p>The Automatically.ai Service may contain links to other independent third-party websites (Third-party Sites). Third-party Sites are not under our control, and we are not responsible for and do not endorse their content or their privacy policies (if any). You will need to make your own independent judgement regarding your interaction with any Third-party Sites, including the purchase and use of any products or services accessible through them.</p>
<p>Please check YouTube Terms of Services&nbsp;<a href="https://www.youtube.com/t/terms">here</a></p>
<p>You can revoke the API clients access to their data via the Google security settings page. Please check Google security settings page for revoke&nbsp;<a href="https://myaccount.google.com/permissions?pli=1">here</a></p>
<p>Please check Google privacy policy&nbsp;<a href="https://policies.google.com/privacy">here</a></p>
<br/><ol className="list" start="8">
<li><h3> Changes</h3></li>
</ol>
<p>We reserve the right to change the Agreement at any time for any reason. Any changes we may make to the Agreement in the future will be notified to you by posting an updated version of the Agreement on this page. Your continued use of or access to the Automatically.ai Service following the changes to the Agreement constitutes acceptance of those changes.</p>
<br/><ol className="list" start="9">
<li><h3> Termination</h3></li>
</ol>
<p>Automatically.ai may terminate the Agreement and suspend your access to all or any part of the Automatically.ai Service immediately by contacting you at your email address on record if:</p>
<ol className="list">
<li>you commit a breach of the Agreement, as determined by us in our discretion, or we suspect that may be the case; or</li>
<li>we consider termination necessary to protect the integrity or security of the systems used by us at any time.</li>
</ol>
<p>If you wish to terminate the Agreement, you must seize usage of the Automatically.ai service.</p>
<p>If you chose to not use the connector anymore or after your trial ended, we kindly recommend that you uninstall the connector from Zoom Marketplace for security reasons.</p>
<p>Automatically.ai may suspend access to all or any part of the Automatically.ai Service immediately when the trial period expired and you didn't uninstall the connector and you are not having paid subscription with Automatically.ai.</p>
<p>On termination of the Agreement by any party and for any reason all rights granted to you under the Agreement shall cease and you must immediately cease all activities authorized by the Agreement, including your use of the Automatically.ai Service.</p>
<br/><ol className="list" start="10">
<li><h3> Disclaimer of Warranties</h3></li>
</ol>
<p>To the maximum extent permitted by law, the Automatically.ai Service is provided &ldquo;as is&rdquo; and &ldquo;as available&rdquo;. Automatically.ai hereby disclaim all warranties of any kind, whether express, implied, statutory or otherwise, including, without limitation, the warranties of merchantability and fitness for a particular purpose and non-infringement. Automatically.ai does not warrant that the Automatically.ai Service will be complete, accurate, reliable, timely, secure, error free or that access thereto will be continuous or uninterrupted. You understand that you are using the Automatically.ai Service at your own discretion and risk.</p>
<p>The provisions in this clause do not affect your statutory or mandatory rights which cannot be excluded by applicable law.</p>
<br/><ol className="list" start="11">
<li><h3> Limitation of Liability</h3></li>
</ol>
<p>In no event will Automatically.ai be liable with respect to any subject matter of the Agreement under contract, negligence, strict liability or other legal or equitable claim for: (i) any indirect, special, incidental or consequential loss or damages; (ii) loss of profit, business, revenue, anticipated savings, business opportunity; (iii) the cost of procurement for substitute products or services; or (iv) the cost of interruption of use or loss or corruption of data.</p>
<p>Our maximum aggregate liability under or in connection with the Agreement shall in all circumstances be limited to the fees paid, if any, by you to Automatically.ai in the one (1) year under this Agreement prior to the cause of action.</p>
<p>Automatically.ai shall have no liability for any failure or delay due to matters beyond its reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.</p>
<br/><ol className="list" start="12">
<li><h3> General Representation and Warranty</h3></li>
</ol>
<p>You represent and warrant that your use of the Automatically.ai Service will be in strict accordance with the Agreement. In particular, you represent and warrant that:</p>
<ol className="list">
<li>you will comply with the content and collaboration platforms Terms of Service at all times;</li>
<li>you will provide us with accurate information (where required);</li>
<li>you will not use the Automatically.ai Service in the event of an emergency;</li>
<li>you will not use the Automatically.ai Service in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with the Agreement, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, including viruses, or harmful data, into the Automatically.ai Service;</li>
<li>you will not infringe our intellectual property rights or those of any third party in relation to your use of the Automatically.ai Service;</li>
<li>you will not do any of the following in relation to your use of the Automatically.ai Service:</li>
</ol>
<ul className="list">
<li>be obscene, offensive, hateful or inflammatory,</li>
<li>defame any person,</li>
<li>promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age,</li>
<li>promote violence,</li>
<li>promote any illegal activity,</li>
<li>promote sexually explicit material,</li>
<li>disclose the name, address, telephone number, e-mail address or any other personal data in respect of any individual without their consent,</li>
<li>harass, upset, embarrass, threaten, alarm or annoy any other person,</li>
<li>be likely to mislead or deceive any person,</li>
<li>impersonate any person, or misrepresent your identity or affiliation with any person,</li>
<li>contain any advertising or promote any services or web links to other sites;</li>
</ul>
<ol className="list">
<li>you will not use the Automatically.ai Service in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users; and</li>
<li>you will not collect or harvest any information or data from any content or our systems or attempt to decipher any transmissions to or from the servers running the Automatically.ai Service.</li>
</ol>
<br/><ol className="list" start="13">
<li><h3> Indemnification</h3></li>
</ol>
<p>You agree to indemnify and hold harmless Automatically.ai Inc. and its respective directors, officers, employees and agents from and against any and all claims and expenses, including attorneys&rsquo; fees, arising out of your use of the Automatically.ai Service, including but not limited to your violation of the Agreement.</p>
<br/><ol className="list" start="14">
<li><h3> Miscellaneous</h3></li>
</ol>
<p>The Agreement constitutes the entire agreement between Automatically.ai and you concerning the provision of the Automatically.ai Services.</p>
<p>The Agreement is between you and us and is not intended to grant rights, including the right to enforce any of its terms, to any other person. Any failure or delay by us to enforce the Agreement or any provision thereof shall not waive our right to do so.</p>
<p>We may transfer our rights and obligations under the Agreement to a third party, but this will not affect your rights under the Agreement.</p>
<p>Each of the terms and conditions of the Agreement operate separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect and will be construed as far as possible to give effect to the parties&rsquo; intentions as originally expressed in the Agreement.</p>
<br/><ol className="list" start="15">
<li><h3> Contact</h3></li>
</ol>
<p>If you have any questions, comments or requests regarding the Agreement, please email:&nbsp;info@automatically.ai</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default TermsOfUsePage;

import axios from 'axios'

 function errorResponseHandler(error) {
     // if has response show the error
     let message = [];
     if(!error) return;
     console.log(error.response);
     try {
      if (error.response) {
        if(error.response.data.message) {
          message.success = false;
          switch(error.response.data.message[0].messages[0].message) {
            case "Identifier or password invalid.":
              message.message = 'Identifier or password invalid';
              break;
            case "Email is already taken.":
              message.message = 'Email is already taken';
              break;
            default: 
            message.message = 'Error';
          }
        }
         if(error.response.data.message.includes('authenticate your request')) {
          localStorage.clear();
           window.location.href = "/";
         } 
      }
    } catch(err) {
    }
    return Promise.reject(message);
 }



// apply interceptor on response
axios.interceptors.response.use(
    response => response,
    errorResponseHandler
);

export default errorResponseHandler;



// import axios from 'axios'

//  function errorResponseHandler(error) {
//    if(!error) return;
//   console.log(error);  
//   if(error.message && error.message[0].messages[0].message === "Identifier or password invalid.") {
//     console.log('Yes')
//   }
//  }

//  axios.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
//   // Do something with response error
//   errorResponseHandler(error.response.data);
//   return Promise.reject(error);
// });


// export default errorResponseHandler;

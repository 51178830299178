import LanguageTypes from './language.types';

const INITIAL_STATE = {
  languages: [],
};


const languagesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case LanguageTypes.ADD_LANGUAGE:
        return {
            ...state,
            languages: action.payload,
        }
        default: 
            return state;
    }
}

export default languagesReducer;
import React, { Component } from "react";
import UploadService from "../../services/upload-files.service";
import { API } from "../../config/api";

export default class UploadFiles extends Component {
  constructor(props) {
    super(props);
    this.selectFiles = this.selectFiles.bind(this);
    this.upload = this.upload.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);

    this.state = {
      selectedImages: [],
      photo: this.props.photo,
      progressInfos: [],
      message: null,
      fileInfos: [],
      totalUpload: 0
    };
  }

  componentDidMount() {
    // UploadService.getFiles().then((response) => {
    //   this.setState({
    //     fileInfos: response.data,
    //   });
    // });
  }

  selectFiles(event) {
      let hasError = false;
      let totalUpload = 0;
      totalUpload = event.target.files.length + this.state.totalUpload;
    if(totalUpload >= 6) {
        hasError = true;
        alert("1تجاوزت العدد المطلوب");
    }
    if(event.target.files.length >= 6) {
        hasError = true;
        alert("1تجاوزت العدد المطلوب");
    }
    if(hasError) {
        return;
    } else {
        let selectedImages = this.state.selectedImages;
        let photos = [];
        photos.push(event.target.files)
        for (let i = 0; i < photos[0].length; i++) {
            selectedImages.push({img: photos[0][i], sourceimg: URL.createObjectURL(photos[0][i])})
        }
        
        this.setState({
        progressInfos: [], 
        selectedFiles: event.target.files,
        selectedImages: selectedImages,
        totalUpload: totalUpload
        }, () => {
            this.uploadFiles();
        });
    }
  }

  async upload(idx, file) {
    let _progressInfos = [...this.state.progressInfos];
    let selectedImages = this.state.selectedImages;

    await UploadService.upload(file, (event) => {
      _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
      this.setState({
        _progressInfos,
      });
    })
      .then((response) => {
        let photo = this.props.photo;
        photo.push(response.data[0]);
        return UploadService.getFiles();
      })
      .then((files) => {
        this.setState({
          fileInfos: files.data
        });
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        this.setState({
          progressInfos: _progressInfos,
          message: "Could not upload the file!",
        });
      });

     _progressInfos = [...this.state.progressInfos];
     selectedImages = this.state.selectedImages;
      let items = selectedImages.filter(item => item.img.name !== file.name);
      let progressInfos = _progressInfos.filter(item => item.fileName !== file.name);
      this.setState({ selectedImages: items, progressInfos: progressInfos });
  }

  uploadFiles() {
    let selectedImages = this.state.selectedImages;
    let hasError = false;
    let _progressInfos = []; 
    for (let i = 0; i < selectedImages.length; i++) {
        if(selectedImages[i].img.size > 30000000){
            alert("حجم الملف اكبر من المطلوب");
            hasError = true;
        } else {
            _progressInfos.push({ percentage: 0, fileName: selectedImages[i].img.name, image: selectedImages[i].sourceimg });
        }
    }
    if(hasError) { 
        return;
    } else {
        this.setState(
            {
                progressInfos: _progressInfos,
                message: null,
            },
            () => {
                for (let i = 0; i < selectedImages.length; i++) {
                this.upload(i, selectedImages[i].img);
                }
            }
        );
    }
  }
  onRemoveItem = (img) => {
      this.props.onRemoveItem(img);
      this.setState({
        totalUpload: this.state.totalUpload - 1
      })
  }
  render() {
    const { progressInfos } = this.state;
    const { photo } = this.props;
    return (
        <div>
      <label>الصور <span className="text-danger">*</span></label>
        <div className="upload-container">
          {photo &&
          photo.map((image, index) => (
            <div className="img-container" key={index}>
                <div className="btn btn-sm btn-danger" onClick={() => this.onRemoveItem(image.url)}><i className="uil uil-multiply"></i></div>
                <img src={API.CMS + `${image.url}`} alt="..." />
            </div>
          ))}

        {progressInfos &&
          progressInfos.map((progressInfo, index) => (
            <div className="img-container" key={index}>
                <div className="btn btn-sm btn-danger"><i className="uil uil-multiply"></i></div>
                <img src={progressInfo.image} alt="..." className="img-thumbnail" />
              <div className="progress">
                <div
                  className="progress-bar progress-bar-info"
                  role="progressbar"
                  aria-valuenow={progressInfo.percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: progressInfo.percentage + "%" }}
                >
                  {progressInfo.percentage}%
                </div>
              </div> 
            </div>
          ))}
<label className="btn btn-success btn-block">
    حدد الصور من جهاز الكمبيوتر الخاص بك 
    <input type="file" multiple onChange={this.selectFiles} accept="image/x-png,image/gif,image/jpeg" required hidden />
</label>


      <small className="badge badge-secondary">
          الحد الأقصى لعدد الصور هو 5.
          انقر على الصورة لتمييز الغلاف.قم بتحميل ملفات JPG أو PNG أصغر من 5 ميجابايت.
      </small>
      </div>
</div>
    );
  }
}
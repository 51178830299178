import React from 'react';
import {Helmet} from "react-helmet";
import Banner from '../../../components/banner/banner.component';

const PrivacyPolicyPage = () => (
    <div id="main">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Automatically.ai | Privacy Policy</title>
                <link rel="canonical" href="https://automatically.ai/privacy-policy" />
            </Helmet>
        <Banner title={'Privacy Policy'} />
        <div className="container">
        <div className="about">
        <div className="jss208">
   <br/>
   <div className="jss209">
   <h2>The Short Version</h2>
<p>Your data belongs to you and not us. We don&rsquo;t resell data about your Zoom recordings, contact. The premise of Automaticlly.ai is to keep most of the User Experience within the collaboration platform (Zoom, Workplace, Teams, Spark) you are using. Automaticlly.ai makes an effort to store the minimum amount of data for the best possible User Experience as well as analytics to improve the Automaticlly.ai experience.</p>
<br/><h2>The Long Version</h2>
<div>
<p>None of your data is stored in Automatically.ai unless you provide authorization by installing Automaticlly.ai app in your collaboration platform. The data that is passed to Automaticlly.ai by the collaboration platform is stored, so that interaction with the collaboration platform API is possible.&nbsp;Automaticlly.ai do not store any of your media content in its platform.</p>
</div>
<div>&nbsp;</div>
<p>The data that is stored in Automaticlly.ai:</p>
<ul className="list">
<li>First name</li>
<li>Last name</li>
<li>End user email address</li>
<li>Account Id</li>
<li>User Id</li>
<li>Role Name</li>
<li>Access Token</li>
<li>Recording information and transcript metadata</li>
</ul>
<div>
<p>Note that Automaticlly.ai do not store actual media content.</p>
</div>
<p>&nbsp;</p>

<h3>1. Zoom Recordings</h3>
<p>While using Automatically.ai, the Client typically may allows us access to their Zoom Recordings, which are then securely uploaded and stored on a Google Drive.</p>
<p>&nbsp;</p>
<h3>2. Usage of Data</h3>
<p>The purpose of data collection within Automatically.ai is to enhance the Client experience.</p>
<p>The Client has a permanent right to access, amend or delete any information related to him/her/it by sending an email to&nbsp;<a class="Underline Link" href="mailto:support@automatically.ai">support@automatically.ai</a></p>
<p>All data provided by the Client, including&nbsp;<strong>Personal Data, Zoom Recordings</strong>&nbsp;will not be given to anyone. We do not, under any circumstances, sell either your Personal Data or your Document Data.</p>
<p>If someone originating from your account at the User&rsquo;s discretion playing role of &ldquo;Client&rdquo; complains or contacts us, we might then contact that person.</p>
<p>Automatically.ai does not collect any personal data that reveals, directly or indirectly, the racial and ethnic origins, the political, philosophical, religious opinions or trade union affiliation of persons, or information which concerns the health or sexual life of any person. Such sensible data shall not be sent, processed or stored through Automaticlly.ai.</p>
<p>Any or all of the above Data may be required by us from time to time in order to provide you with the best possible service and experience when using our Website. Specifically, Personal Data may be used by us for the following reasons:</p>
<ul className="list">
<li>internal record keeping;</li>
<li>improvement of our products / services;&nbsp;</li>
</ul>
<p>&nbsp;</p>
<h3>3.Third party websites and services</h3>
<p>Automatically.ai may, from time to time, employ the services of other parties for dealing with certain processes necessary for the operation of the Website.&nbsp;</p>
<p>Unless we specifically say otherwise, your Data will never be intentionally disclosed to third parties for the sake of administering better service, but never for marketing purposes through various affiliates and / or other companies within our group.</p>
<p>Any Personal Data used by such parties is used only to the extent required by them to perform the services that we request. Any use for other purposes is strictly prohibited. Furthermore, any Data that is processed by third parties will be processed within the terms of this privacy policy and in accordance with the Data Protection Act 1998 and GDPR</p>
<p>&nbsp;</p>
<h3>4. Data Retention</h3>
<p>The purpose of data collection within Automatically.ai is to enhance the Client experience. Data obtained via Zoom APIs (Personal &amp; account information and recordings), as mentioned in the section Collected Data, will be retained as long as the App is installed by the Client in their Zoom account.</p>
<p>On un-installation of the app from the Zoom Marketplace, the Client has a choice to state whether or not they would like to grant your App with data retention permission. We will:</p>
<ol className="list">
<li>Delete all data within 10 days of un-installation, if the Client has not granted our App permission to retain data.</li>
<li>Delete all data within 30 days of un-installation, if the Client has granted our App permission to retain data.</li>
</ol>
<p>&nbsp;</p>
<h3>5. Security</h3>
<div>
<p>Automatically.ai takes data security very seriously and its system is compliant with the latest web security standards.Automatically.ai uses full SSL encryption, between its server and the Client once the latter is logged in and for the transfer to Google Drive. The login system is protected against brute&shy;force attacks, injection of malicious scripts and other commonly known attack types.</p>
</div>
<p>Automatically.ai application is running on the DigitalOcean Cloud. All servers are always up&shy;to&shy;date with the latest security releases. A full backup is made on a weekly basis and all data is constantly stored on different hard&shy; drives to prevent data loss in case of a hardware failure. Personal data pertaining to the Clients will be deleted within 2 weeks after the closing or deletion of the Client&rsquo;s Account.</p>
<p>Only authorized employees have access to view Personal Data, Zoom Recordings and Viewer Email Addresses.</p>
<p>We endeavour to do our best to protect your personal Data. However, transmission of information over the internet is not always perfectly secure and is done at your own risk. We cannot ensure the security of your Data transmitted to Automatically.ai.</p>
<p>&nbsp;</p>
<h3>6. Cookies</h3>
<p>This Website may place and access certain Cookies on your computer. Automatically.ai uses Cookies to improve your experience of using the Website and to improve our range of products and services.</p>
<p>Automatically.ai has carefully chosen these Cookies and has taken steps to ensure that your privacy is protected and respected at all times. All Cookies used by this Website are used in accordance with current EU Cookie Law.</p>
<p>By using our Website and Services, you are giving your consent to the placing of Cookies, you are enabling Automatically.ai to provide a better experience and service to you. You may, if you wish, deny consent to the placing of Cookies; however certain features of the Website may not function fully or as intended.</p>
<p>These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website or make use of e-billing services.</p>
<p>You can choose to enable or disable Cookies in your internet browser. By default, most internet browsers accept Cookies but this can be changed. For further details, please consult the help menu in your internet browser.</p>
<p>You can choose to delete Cookies at any time; however you may lose any information that enables you to access the Website more quickly and efficiently including, but not limited to, personalisation settings.</p>
<p>It is recommended that you ensure that your internet browser is up-to-date and that you consult the help and guidance provided by the developer of your internet browser if you are unsure about adjusting your privacy settings.</p>
<p>&nbsp;</p>
<h3>7. Communication by Automatically.ai</h3>
<p>Automatically.ai may send emails to the Client for technical or administrative purposes or to inform the Client of the latest developments.</p>
<p>&nbsp;</p>
<h3>8. General</h3>
<p>You may not transfer any of your rights under this privacy policy to any other person. We may transfer our rights under this privacy policy where we reasonably believe your rights will not be affected.</p>
<p>If any court or competent authority finds that any provision of this privacy policy (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this privacy policy will not be affected.</p>
<p>Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.</p>
<p>The Privacy Policy is governed by and interpreted according to the laws of India. Any dispute, controversy or claim arising under, out of or relating to the validity, interpretation and performance of this Privacy Policy shall be referred to and finally determined by the competent courts in Mumbai, India and Indian law shall apply.</p>
<p>For any questions or concerns relating to the use of your User Data while using Automatically.ai, you are welcome to email to&nbsp;<a class="Underline Link" href="mailto:support@automatically.ai">support@automatically.ai</a></p>
<p>&nbsp;</p>
<h3>9. Changes to this privacy policy</h3>
<p>Automatically.ai reserves the right to change this privacy policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediately posted on the Website and you are deemed to have accepted the terms of the privacy policy on your first use of the Website following the alterations. You may contact Automatically.ai by email at&nbsp;<a class="Underline Link" href="mailto:support@automatically.ai">support@automatically.ai</a></p>
<p><em>Last updated on 24 Jul 2020</em></p>

   </div>
</div>
        </div>
        </div>
    </div>
)

export default PrivacyPolicyPage;

import React from 'react';

import Banner from '../../../components/banner/banner.component';

class Guide extends React.Component {

    render() { 
        return (
                
            <div id="main" >
            <Banner title={'Guide'} />
            <div id="features" className="features">
                <div className="flex-split">
                    <div className="container">
                        <div className="row align-center"> 
                            <div className="col-md-5">
                            <div className="f-left">
                                <div className="left-content wow fadeInLeft animated">
                                <h2>Install Automatically.ai APP</h2>
                                <p> Not just your messages, but all your files, images, PDFs, documents, and spreadsheets can be dropped
                                    right into Slack and shared with anyone you want. Add comments, star for later reference.
                                </p></div>
                            </div>
                            </div>
                            <div className="col-md-6 offset-md-1">
                            <div className="img-cover">
                                <img className="img-fluid" src="/assets/images/guide/Zoom-Guide-01.jpg" alt="Feature" />
                            </div>
                            </div>
                        </div> 
                        </div>
                    </div>

                    <div className="flex-split">
                        <div className="container">
                        <div className="row align-center">
                            <div className="col-md-6">
                            <div className="img-cover">
                                <img className="img-fluid" src="/assets/images/guide/Zoom-Guide-02.jpg" alt="Feature" />
                            </div>
                            </div>
                            <div className="col-md-5  offset-md-1">
                            <div className="f-left">
                                <div className="left-content wow fadeInLeft animated">
                                <h2>Consent for the app to access your Zoom account</h2>
                                <p> Not just your messages, but all your files, images, PDFs, documents, and spreadsheets can be dropped
                                    right into Slack and shared with anyone you want. Add comments, star for later reference.
                                </p></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>


                    <div className="flex-split">
                        <div className="container">
                        <div className="row align-center">
                            <div className="col-md-5">
                            <div className="f-left">
                                <div className="left-content wow fadeInLeft animated">
                                <h2>Install Automatically.ai APP</h2>
                                <p> Not just your messages, but all your files, images, PDFs, documents, and spreadsheets can be dropped
                                    right into Slack and shared with anyone you want. Add comments, star for later reference.
                                </p></div>
                            </div>
                            </div>
                            <div className="col-md-6 offset-md-1">
                            <div className="img-cover">
                                <img className="img-fluid" src="/assets/images/guide/Zoom-Guide-03.jpg" alt="Feature" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="flex-split">
                        <div className="container">
                        <div className="row align-center">
                            <div className="col-md-6">
                            <div className="img-cover">
                                <img className="img-fluid" src="/assets/images/guide/Zoom-Guide-04.jpg" alt="Feature" />
                            </div>
                            </div>
                            <div className="col-md-5  offset-md-1">
                            <div className="f-left">
                                <div className="left-content wow fadeInLeft animated">
                                <h2>Consent for the app to access your Zoom account</h2>
                                <p> Not just your messages, but all your files, images, PDFs, documents, and spreadsheets can be dropped
                                    right into Slack and shared with anyone you want. Add comments, star for later reference.
                                </p></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>


                    <div className="flex-split">
                        <div className="container">
                        <div className="row align-center">
                            <div className="col-md-5">
                            <div className="f-left">
                                <div className="left-content wow fadeInLeft animated">
                                <h2>Install Automatically.ai APP</h2>
                                <p> Not just your messages, but all your files, images, PDFs, documents, and spreadsheets can be dropped
                                    right into Slack and shared with anyone you want. Add comments, star for later reference.
                                </p></div>
                            </div>
                            </div>
                            <div className="col-md-6 offset-md-1">
                            <div className="img-cover">
                                <img className="img-fluid" src="/assets/images/guide/Zoom-Guide-05.jpg" alt="Feature" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="flex-split">
                        <div className="container">
                        <div className="row align-center">
                            <div className="col-md-6">
                            <div className="img-cover">
                                <img className="img-fluid" src="/assets/images/guide/Zoom-Guide-06.jpg" alt="Feature" />
                            </div>
                            </div>
                            <div className="col-md-5  offset-md-1">
                            <div className="f-left">
                                <div className="left-content wow fadeInLeft animated">
                                <h2>Consent for the app to access your Zoom account</h2>
                                <p> Not just your messages, but all your files, images, PDFs, documents, and spreadsheets can be dropped
                                    right into Slack and shared with anyone you want. Add comments, star for later reference.
                                </p></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>


                    <div className="flex-split">
                        <div className="container">
                        <div className="row align-center">
                            <div className="col-md-5">
                            <div className="f-left">
                                <div className="left-content wow fadeInLeft animated">
                                <h2>Install Automatically.ai APP</h2>
                                <p> Not just your messages, but all your files, images, PDFs, documents, and spreadsheets can be dropped
                                    right into Slack and shared with anyone you want. Add comments, star for later reference.
                                </p></div>
                            </div>
                            </div>
                            <div className="col-md-6 offset-md-1">
                            <div className="img-cover">
                                <img className="img-fluid" src="/assets/images/guide/Zoom-Guide-07.jpg" alt="Feature" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="flex-split">
                        <div className="container">
                        <div className="row align-center">
                            <div className="col-md-6">
                            <div className="img-cover">
                                <img className="img-fluid" src="/assets/images/guide/Zoom-Guide-08.jpg" alt="Feature" />
                            </div>
                            </div>
                            <div className="col-md-5  offset-md-1">
                            <div className="f-left">
                                <div className="left-content wow fadeInLeft animated">
                                <h2>Consent for the app to access your Zoom account</h2>
                                <p> Not just your messages, but all your files, images, PDFs, documents, and spreadsheets can be dropped
                                    right into Slack and shared with anyone you want. Add comments, star for later reference.
                                </p></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Guide;

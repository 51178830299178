import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import NotificationSystem from 'react-notification-system';
import UploadFiles from "../../../components/upload-files/upload-files.component";
import { API } from "../../../config/api";
import axios from "axios";

class NewOffer extends React.Component {
    notificationSystem = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            photosAll: [],
            units: [],
            categories: [],
            specifies: [],
            toUrl: '/app/myoffers',
            offer: {
                name: '',
                amount: '',
                category: '',
                unit: '',
                price: '',
                city: this.props.currentUser.city.id,
                priceByAgreement: true,
                pricePerUnit: false,
                transport: false,
                photo: [],
                description: '',
                location: '',
            }
        }
    }

    handleInputChange = (event) => {
        const { value, name } = event.target;
        let newArray = this.state.offer;
        newArray = { ...newArray, [name]: value }
        this.setState({
            offer: newArray
        })
    };

    updatePricingType = (event) => {
        const { name } = event.target;
        let newArray = this.state.offer;
        let v = this.state.offer[name];
        v = !v;
        newArray.priceByAgreement = false;
        newArray.pricePerUnit = false;
        newArray.price = '';
        newArray = { ...newArray, [name]: v };
        this.setState({
            offer: newArray
        })
    }
    handleCheckbox = (event) => {
        const { name } = event.target;
        let newArray = this.state.offer;
        let v = this.state.offer[name];
        v = !v;
        newArray = { ...newArray, [name]: v };
        this.setState({
            offer: newArray
        })       
    }
    handleCategoryChange = async (event) => {
        const { value, name } = event.target;
        let newArray = this.state.offer;
        newArray = { ...newArray, [name]: value }
        this.setState({
            offer: newArray
        });
        let specifies = [];
        specifies = await axios.get(`${API.CMS}/specifies?category.id=${value}`);
        this.setState({
            specifies: specifies.data
        });
    }

    async componentDidMount() {
        let units = await axios.get(`${API.CMS}/units`);
        let categories = await axios.get(`${API.CMS}/categories`);
        this.setState({
            units: units.data,
            categories: categories.data
        });
    }

    onRemoveItem = i => {
        let photo = this.state.offer.photo;
        let offer = this.state.offer;
        photo = photo.filter((item, j) => i !== item.url);
        offer = { ...offer, photo }
        this.setState({offer})
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const notification = this.notificationSystem.current;
        const { offer } = this.state;
        const { currentUser } = this.props;
        let city = currentUser.city;
        if(currentUser.city.id) {
            city = currentUser.city.id;
        }
        let photo = [];
        offer.photo.forEach(element => {
            photo.push(element.id);
        });
        let data =  {
            name: offer.name,
            amount: offer.amount,
            priceByAgreement: offer.priceByAgreement,
            pricePerUnit: offer.pricePerUnit,
            price: offer.price,
            transport: offer.transport,
            description: offer.description,
            location: offer.location,
            photo: photo,
            unit: offer.unit,
            // specify: offer.specify,
            category: offer.category,
            user: currentUser.id,
            city: city,
        };
        const savedOffer = await axios.post(API.CMS + `/offers`, data);
        if(savedOffer.data) {
            setTimeout(() => {
                this.setState({ 
                    redirect: true
                })
            }, 500);
            notification.addNotification({
              message: 'تم حفظ الحفظ بنجاح',
              position: 'tr',
              dismissible: 'click',
              level: 'success'
            });
        }
      }

    render() {
    const { currentUser, lang } = this.props;
    const { offer, photosAll, units, categories, toUrl, redirect } = this.state;
    // specifies
    
    return (
            <div>
                { redirect ? (<Redirect push to={toUrl}/>) : null }
                <NotificationSystem ref={this.notificationSystem} />
                <form className="login-form mt-4" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group position-relative">
                            <label htmlFor="lastname">
                            {lang.name_what_you_sell} <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control pl-5"
                                placeholder={lang.name_what_you_sell}
                                id="name"
                                name="name"
                                value={offer.name}
                                onChange={this.handleInputChange}
                                required
                            />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group position-relative">
                            <label htmlFor="category">
                            {lang.category} <span className="text-danger">*</span>
                            </label>
                            <select
                                className="form-control custom-select"
                                id="category"
                                value={offer.category}
                                onChange={this.handleCategoryChange}
                                name="category"
                                required
                            >
                                <option value={''}>{lang.select}</option>
                                {categories.map((category, idx) => (
                                    <option key={idx} value={category.id}>{category.name}</option>
                                ))}
                            </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group position-relative">
                            <label htmlFor="amount">
                            {lang.quantity} <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control pl-5"
                                placeholder={lang.quantity}
                                id="amount"
                                name="amount"
                                value={offer.amount}
                                onChange={this.handleInputChange}
                                required
                            />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group position-relative">
                            <label htmlFor="unit">
                            {lang.unit} <span className="text-danger">*</span>
                            </label>
                            <select
                                className="form-control custom-select"
                                id="unit"
                                value={offer.unit}
                                onChange={this.handleInputChange}
                                name="unit"
                                required
                            >
                                <option value={''}>{lang.select}</option>
                                {units.map((unit, idx) => (
                                    <option key={idx} value={unit.id}>{unit.name}</option>
                                ))}
                            </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group position-relative">
                                <label htmlFor="unit">
                                {lang.pricing}<span className="text-danger">*</span>
                                </label>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        name="pricePerUnit"
                                        checked={offer.pricePerUnit}
                                        onChange={this.updatePricingType}
                                        id="pricePerUnit"
                                        
                                    />
                                    <label className="custom-control-label" htmlFor="pricePerUnit">{lang.price_per_unit}</label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        name="priceByAgreement"
                                        checked={offer.priceByAgreement}
                                        onChange={this.updatePricingType}
                                        id="priceByAgreement"
                                        
                                    />
                                    <label className="custom-control-label" htmlFor="priceByAgreement">{lang.price_by_agreement}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {offer.pricePerUnit && 
                                <div className="form-group position-relative">
                                <label htmlFor="amount">
                                {lang.price} <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control pl-5"
                                    placeholder={lang.price}
                                    id="price"
                                    name="price"
                                    value={offer.price}
                                    onChange={this.handleInputChange}
                                    required
                                />
                                </div>
                            }
                        </div>
                        

                        {/* <div className="col-lg-6">
                            <div className="form-group position-relative">
                            <label htmlFor="specify">
                            {lang.determine}
                            </label>
                            <select
                                className="form-control custom-select"
                                id="specify"
                                value={offer.specify}
                                onChange={this.handleInputChange}
                                name="specify"
                                required
                            >
                                <option value={''}>{lang.select}</option>
                                {specifies.map((specify, idx) => (
                                    <option key={specify.id} value={specify.id}>{specify.title}</option>
                                ))}
                            </select>
                            </div>
                        </div> */}

                        <div className="col-lg-12">
                            <div className="form-group multi-preview">
                                {(photosAll || []).map((url, idx) => (
                                    <img key={idx} src={url} alt="..." />
                                ))}
                            </div>
                            <div className="form-group multi-preview">
                                <UploadFiles uploadedFiles={(e) => this.uploadedFiles(e)} photo={this.state.offer.photo} onRemoveItem={this.onRemoveItem} required/>
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="form-group position-relative">
                            <label htmlFor="location">
                            {lang.your_location} <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control pl-5"
                                placeholder={lang.your_location}
                                id="location"
                                name="location"
                                value={offer.location}
                                onChange={this.handleInputChange}
                                required
                            />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group position-relative">
                            <label htmlFor="unit">
                            {lang.delivery} 
                            </label>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" checked={offer.transport} onChange={this.handleCheckbox} name="transport" id="transport" />
                                <label className="custom-control-label" htmlFor="transport">{lang.with_transport}</label>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group position-relative">
                                <label htmlFor="description">
                                {lang.details} <span className="text-danger">*</span>
                                </label>
                                <textarea
                                type="text"
                                className="form-control pl-5"
                                placeholder={lang.details}
                                id="description"
                                name="description"
                                value={currentUser.description}
                                onChange={this.handleInputChange}
                                required
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="col-lg-12">
                        <button className="btn btn-success" type="submit">{lang.send}</button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    lang: state.lang.languages
});

export default connect(mapStateToProps)(NewOffer);

import React from 'react';
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';

import changeStyle from '../../redux/load-style/load-style.actions';

import Dashboard from '../../pages/auth/dashboard/dashboard.component';
import { API } from '../../config/api';
import Profile from '../../pages/auth/profile/profile.component';
import UserOffers from '../../pages/auth/offers/UserOffers.component';
import MyAccount from '../../pages/auth/myAccount/MyAccount.component'; 
import newOffer from '../../pages/auth/new-offer/new-offer.component';


class PrivateLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: true
        }
    }
    async componentDidMount() {
        const { token } = this.props;
        if (token) {
            axios.interceptors.request.use(
              function(config) {
                config.headers.Authorization = 'Bearer ' + token;
                return config;
              },
              error => {
                return Promise.reject(error);
              }
            );
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          }
        this.setState({
            isLogged: true
        });
    }

    logOut = () => {
        localStorage.clear();
        window.location.href = "/";
    }

    render() {
        const { currentUser, lang } = this.props;
        const NotFoundRedirect = () => <Redirect to='/' />
        return (
            <div>
            <section className="bg-profile d-table w-100 bg-primary" style={{background: "url('/assets/images/bg.png') center center"}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card public-profile border-0 rounded shadow" style={{zIndex: 1}}>
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-lg-2 col-md-3 text-md-left text-center">
                                    {currentUser.company_logo ? (
                                        <img src={`${API.CMS}${currentUser.company_logo.url}`} className="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="" />
                                    ) : (
                                        <img src="https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg" className="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="" />
                                    )}
                                    </div> 
                                    <div className="col-lg-10 col-md-9">
                                        <div className="row align-items-end">
                                            <div className="col-md-7 text-md-left text-center mt-4 mt-sm-0">
                                                <h3 className="title mb-2">{currentUser.company_name}</h3>
                                                <small className="text-muted h6 mr-2">{currentUser.city.name}</small>
                                            </div> 
                                        </div> 
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div> 
                </div> 
            </div>   
        </section> 
        <section className="section mt-60">
            <div className="container mt-lg-3">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12 d-lg-block d-none">
                        <div className="sidebar sticky-bar p-4 rounded shadow">
                        <div className="widget">
                                <div className="row">
                                    <div className="col-6 mt-4 pt-2">
                                        <Link to="/app/profile" className="accounts active rounded d-block shadow text-center py-3">
                                            <span className="pro-icons h3 text-muted"><i className="uil uil-user-circle"></i></span>
                                            <h6 className="title text-dark h6 my-0">{lang.my_account}</h6>
                                        </Link>
                                    </div>

                                    <div className="col-6 mt-4 pt-2">
                                        <Link to="/app/myoffers" className="accounts rounded d-block shadow text-center py-3">
                                            <span className="pro-icons h3 text-muted"><i className="uil uil-document-layout-center"></i></span>
                                            <h6 className="title text-dark h6 my-0">{lang.my_offers}</h6>
                                        </Link>
                                    </div>

                                    <div className="col-6 mt-4 pt-2">
                                        <Link to="/app/settings" className="accounts rounded d-block shadow text-center py-3">
                                            <span className="pro-icons h3 text-muted"><i className="uil uil-setting"></i></span>
                                            <h6 className="title text-dark h6 my-0">{lang.settings}</h6>
                                        </Link>
                                    </div>

                                    <div className="col-6 mt-4 pt-2">
                                        <div onClick={this.logOut} className="accounts rounded d-block shadow text-center py-3">
                                            <span className="pro-icons h3 text-muted"><i className="uil uil-sign-out-alt"></i></span>
                                            <h6 className="title text-dark h6 my-0">{lang.logout}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12">
                        {this.state.isLogged &&
                            <Switch>
                                <Route exact path="/app/dashboard" component={Dashboard} />
                                <Route exact path="/app/profile" component={Profile} />
                                <Route exact path="/app/myoffers" component={UserOffers} />
                                <Route exact path="/app/settings" component={MyAccount} />
                                <Route exact path="/app/new-offer" component={newOffer} />
                                <Route component={NotFoundRedirect} />
                            </Switch>
                        }
                    </div>
                </div>
            </div>
        </section>
        </div>
        );
    }
}
const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    token: state.user.token,
    lang: state.lang.languages
  });

const mapDispatchToProps = dispatch => ({
  changeStyle: currentStyle => dispatch(changeStyle(currentStyle))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateLayout);
  
  
